import React, { useEffect,useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "shards-react";
import 'antd/dist/antd.css';
import { Popover } from 'antd';

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import PopUp from "../../components/common/PopUp";

import DummyForm from "./components/DummyForm";

import { getBatches, createBatch, deleteBatch, editBatch } from "../../redux/actions/batchActions";

const Dummy = () => {

  const dispatch = useDispatch();
  const [addItem,setAddItem] = useState(true);
  const [item,setItem] = useState(null);

  const itemList = useSelector(state => state.batch);
  const items = itemList.batches;

  // Get

  useEffect(() => {
    dispatch(getBatches());
  },[dispatch]);

  //for pagination
  const [currentPage,setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem,indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }   

  // Pop up
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = (id) => {
    setDeleteItemId(id);
    setDeletePopUpVisible(true);
  }  

  const showPopUp = (message) => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  }

  // Delete

  const deleteHandler = async() => {
        dispatch(deleteBatch(deleteItemId));
        if(currentItems.length===1){
          paginate(Math.max(currentPage-1,1))
        }
        showPopUp("Item deleted Successfully!");
  }

  // Edit

  const editHandler = (data) => {
      setItem(data);
      setAddItem(false);
  }

  // Create and Edit
  
  const submitHandler = async(e) => {
    e.preventDefault();
    if(addItem===true){
        const itemName = (e.target.batchName.value);
        const data = {
          "batch_name" : itemName,
          "status" : false,
          "institute" : 1
        }
        dispatch(createBatch(data));
        if(currentItems.length===itemsPerPage){
          paginate(currentPage+1)
        }
        showPopUp("Item added Successfully!");
    }
    else{
        const itemName = (e.target.batchName.value);
        const data = {
          "id" : item.id,
          "batch_name" : itemName
        }
        dispatch(editBatch(data))
        setAddItem(true)
        showPopUp("Item edited Successfully!");
    }
    setItem(null);
  } 
  
  const addItemTrueHandler = () => {
    setAddItem(true);
    setItem(null);
  }

  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }} 
        theme="white" size="sm" className="mx-1"
      >
        Cancel
      </Button>
      <Button 
        onClick={()=>{
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        size="sm" className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage}/>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Title"
          subtitle="Sub-Title"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Form */}
      <Row>
        <Col lg="4" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom" style={{display:"flex",justifyContent: "space-between"}}>
              <h6 
                className="m-0" 
                style={{display:"flex",justifyContent: "flex-right",flex:"1"}}
              >
                {addItem ? "Add Item" : "Edit Item"}
              </h6>
              {!addItem &&
                <span 
                  type="primary" 
                  size="small" 
                  style={{display:"flex",justifyContent:"flex-end",flex:"1",cursor:"pointer",textAlign:"left",verticalAlign:"center"}}
                  onClick={() => addItemTrueHandler()}
                >
                  <Button type="primary" size="sm" className="mx-1" style={{paddingBottom:"5px"}}>
                    Add
                  </Button>
                </span>
              }
            </CardHeader>
            <DummyForm submitHandler = {submitHandler} data={item}/> 
          </Card>
        </Col>

        {/* List */}
            <Col lg="8" className="mb-4" style={{textAlign:"center"}}>
              <Card small className="mb-4 " style={{textAlign:"left"}}>
                <CardHeader className="border-bottom">
                  <h6 className="m-0">LIST</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody> 
                      {Array.isArray(currentItems) && currentItems.map((data,index) => 
                        <tr key={data.id}>
                        <td>{index+1+itemsPerPage*(currentPage-1)}.</td>
                        <td>{data.batch_name}</td>
                        <td>
                          <Button type="primary" size="small" className="mx-1" onClick={()=> editHandler(data)}>
                            Edit
                          </Button>
                            <Popover 
                              key={data.id}
                              content={content} 
                              title="Sure to delete?" 
                              trigger="click" 
                              visible={deletePopUpVisible && data.id===deleteItemId}
                              className="mx-1">
                              <Button
                                theme="danger"
                                onClick={() => openDeletePopUp(data.id)} type="danger" size="small">
                                Delete
                              </Button>
                            </Popover>
                        </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                </CardBody>
            </Card>
            {items && items.length > itemsPerPage && 
              <Pagination 
                paginate = {paginate}
                currentPage = {currentPage} 
                totalItems = {items.length} 
                itemsPerPage = {itemsPerPage}
              />}
            </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Dummy);