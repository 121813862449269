import { API } from "../../backend";
import axios from "axios";
import { PROFILE_LOADED, PROFILE_LOADING, SET_PROFILE} from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getProfile = userId => async dispatch => {
  dispatch(profileLoading());
  axios
    .get(`${API}/usercreate/${userId}`)
    .then(res => {
      const profile = res.data;
      dispatch(setProfile(profile));
      dispatch(profileLoaded());
    })
    .catch(err => {
      dispatch(profileLoaded());
    });
};

export const setProfile = profile => {
  return {
    type: SET_PROFILE,
    payload: profile
  };
};

export const profileLoading = () => {
  return {
    type: PROFILE_LOADING
  };
};

export const profileLoaded = () => {
  return {
    type: PROFILE_LOADED
  };
};

export const editProfile = (profile, userId) => async dispatch => {
  try {
    dispatch(profileLoading());
    await axios.patch(`${API}/usercreate/${userId}`, profile);
    dispatch(getProfile(userId));
    dispatch(profileLoaded());
  } catch (error) {
    dispatch(profileLoaded());
  }
};
