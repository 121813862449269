import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from "shards-react";
import { Popover } from "antd";
import "antd/dist/antd.css";

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import AddStudentForm from "./components/AddStudentForm";
import profileAvatar from "../../images/signin/profile_avatar.jpg";
import { DeleteOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';

import { createStudent, deleteStudent, editStudent, getStudents } from "../../redux/actions/studentActions";

import PopUp from "../../components/common/PopUp";
import { getClasses } from "../../redux/actions/classActions";
import { editProfile } from "../../redux/actions/profileActions";

const StudentList = () => {
  const dispatch = useDispatch();
  //here item is referring to Student
  const [addItem,setAddItem] = useState(true);
  const [item,setItem] = useState(null);
  
  const [className,setClassName] = useState("");
  const [batchName,setBatchName] = useState("");

  

  const [view, setView] = useState("list");

  const studentList = useSelector(state => state.student);
  const { students, mobileError, successCreate } = studentList;

  const auth = useSelector(state => state.auth);
  const user = auth.user;

  // get
  useEffect(() => {
    if (successCreate !== null && auth && user && user.institute) {
      setView("list");
      showPopUp("Student added Successfully!");
      dispatch(getStudents(user.institute.id));
    } else if (auth && user && user.institute) {
      dispatch(getStudents(user.institute.id));
      dispatch(getClasses(user.institute.id));
    }
  }, [dispatch, auth, user, successCreate]);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = students.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  //for file upload
  const [fileLink, setFileLink] = useState(null);
  const [fileName, setFileName] = useState("");

  // edit and create
  const submitHandler = e => {
    e.preventDefault();
    if(addItem === true){
      var itemData = new FormData();
      itemData.append("username", e.target.mobileNumber.value);
      itemData.append("password", e.target.password.value);
      itemData.append("email", e.target.email.value);
      itemData.append("roles", 3);
      if (fileName !== "") {
        itemData.append("image", fileName);
      }
      else {
        itemData.append("image", null);
      }
      itemData.append("full_name", e.target.fullName.value);
      itemData.append("is_active", true);
      itemData.append("mobile", e.target.mobileNumber.value);
      itemData.append("institute", user.institute.id);
      itemData.append("current_user", user.id);
      itemData.append("class_name", e.target.className.value);
      itemData.append("institute_data", user.institute.id);
      itemData.append("batch_name", e.target.batchName.value);
      dispatch(createStudent(itemData, user.institute.id));
    }
    else{
        var studentUserData = new FormData();
        if(e.target.password.value !==null && e.target.password.value !== ""){
          studentUserData.append("password", e.target.password.value);
        }
        studentUserData.append("email", e.target.email.value);
        if (fileName !== "") {
          studentUserData.append("image", fileName);
        }
        else {
          studentUserData.append("image", null);
        }
        studentUserData.append("full_name", e.target.fullName.value);

        var studentData = new FormData();
        studentData.append("class_name", e.target.className.value);
        studentData.append("batch_name", e.target.batchName.value);
        dispatch(editProfile(studentUserData, item.user.id));
        dispatch(editStudent(item.id,studentData,user.institute.id));
        setView("list")
        showPopUp("Student Details Updated Successfully!");
    }
    e.target.reset(); 
    setClassName("");
    setBatchName("");
    setFileLink(null);
    setFileName("");
  };

  const editHandler = data => {
    setClassName(data.class_name.id);
    setBatchName(data.batch_name.id)
    setItem(data);
    setAddItem(false);
    setFileLink(data.user.image)
    setView("form");
  };
  
  
//delete 
  const deleteHandler = async () => {
    dispatch(deleteStudent(deleteItemId, user.institute.id,deleteUserId));
    if (currentItems.length === 1) {
      paginate(Math.max(currentPage - 1, 1));
    }
    dispatch(getStudents(user.institute.id));
    showPopUp("Student deleted Successfully!");
  };


  //popup
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = (id,userIdToDelete) => {
    setDeleteItemId(id);
    setDeleteUserId(userIdToDelete);
    setDeletePopUpVisible(true);
  };

  const showPopUp = message => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  };

  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }}
        theme="white"
        type="default"
        size="small"
        className="mx-1"
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        type="primary"
        size="small"
        className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage} />
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Students"
          subtitle="Students"
          className="text-sm-left mb-3"
        />
        {view === "form" ? (
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              setView("list");
              setItem(null)
            }}
            className="ml-auto mb-3"
          >
            Back
          </Button>
        ) : (
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              setView("form");
              setItem(null)
              setFileLink(null);
              setFileName("");
              setClassName("");
              setBatchName("");  
            }}
            className="ml-auto mb-3"
          >
            Add Student
          </Button>
        )}
      </Row>

      <Row>
        {/* Sutudents List */}
        {view === "list" ? (
          <Col className="mb-4" style={{ textAlign: "center" }}>
            <Card small className="mb-4 " style={{ textAlign: "left" }}>
              <CardHeader className="border-bottom">
                <h6 className="m-0">STUDENT LIST</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Student Name
                      </th>
                      <th scope="col" className="border-0">
                        Image
                      </th>
                      <th scope="col" className="border-0">
                        Username
                      </th>
                      <th scope="col" className="border-0">
                        Email
                      </th>
                      <th scope="col" className="border-0">
                        Mobile No.
                      </th>
                      <th scope="col" className="border-0">
                        Class
                      </th>
                      <th scope="col" className="border-0">
                        Batch
                      </th>
                      <th scope="col" className="border-0">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentItems) &&
                      currentItems.map((data, index) => (
                        <tr key={data.id}>
                          <td>
                            {index + 1 + itemsPerPage * (currentPage - 1)}.
                          </td>
                          <td>{data.user.full_name}</td>
                          <td>
                            <a href={data.user.image} target="_blank" rel="noopener noreferrer">
                              <img
                                src={data.user.image}
                                width="40"
                                height="40"
                                alt={data.user.full_name}
                              />
                            </a>
                          </td>
                          <td>{data.user.username}</td>
                          <td>
                            <a href={`mailto:${data.user.email}`}>
                              {data.user.email}
                            </a>
                          </td>
                          <td>{data.user.mobile}</td>
                          <td>{data.class_name.class_name}</td>
                          <td>{data.batch_name.batch_name}</td>
                          <td>
                          <Button
                            type="primary"
                            size="small"
                            className="mx-1"
                            onClick={() => editHandler(data)}
                          >
                            Edit
                          </Button>
                          <Popover
                            key={data.id}
                            content={content}
                            title="Sure to delete?"
                            trigger="click"
                            visible={
                              deletePopUpVisible && (Number)(data.id) === (Number)(deleteItemId)
                            }
                            className="mx-1"
                          >
                            <Button
                              theme="danger"
                              onClick={() => openDeletePopUp(data.id,data.user.id)}
                              type="danger"
                              size="small"
                            >
                              Delete
                            </Button>
                          </Popover>
                        </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            {students !== undefined && students.length > itemsPerPage && (
              <Pagination
                paginate={paginate}
                currentPage={currentPage}
                totalItems={students.length}
                itemsPerPage={itemsPerPage}
              />
            )}
          </Col>
        ) : (
          <>
            <Col lg="3">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Student Image</h6>
                </CardHeader>

                <ListGroup flush>
                  <ListGroupItem className="px-4 text-center">
                    <div className="mb-3 mx-auto">
                      <img
                        className="rounded-circle"
                        src={
                          fileLink == null
                            ? profileAvatar
                            : fileLink
                        }
                        alt="student"
                        width="110"
                        height="110"
                      />
                    </div>

                    <span className="text-muted d-block mb-2">
                      {fileName.name}
                    </span>
                    <div className="mb-3">
                      <input
                        type="file"
                        className="file"
                        id="file"
                        hidden
                        accept="image/*"
                        onChange={e => {
                          setFileLink(URL.createObjectURL(e.target.files[0]));
                          setFileName(e.target.files[0]);
                        }}
                      />
                      <Button theme="primary" className="ml-auto circle-btn my-auto">
                        <label
                          htmlFor="file"
                          style={{margin:"0",padding:"0"}}
                        >
                            <UploadOutlined className="icon"/>
                        </label>
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        theme="danger"
                        className="circle-btn my-auto"
                        onClick={() => {
                          setFileLink(null);
                          setFileName("");
                        }}
                        style={{ padding: "9px", marginBottom: "3px" }}
                      >
                        <DeleteOutlined className="icon"/>
                      </Button>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <AddStudentForm
                submitHandler={submitHandler}
                mobileError={mobileError}
                data={item}
                className={className}
                onClassNameChange={e => setClassName(e.target.className.value)}
                batchName={batchName}
                onBatchNameChange={e => setBatchName(e.target.value)}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default withRouter(StudentList);
