import { API } from "../../../backend";
import axios from "axios";

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormSelect,
  FormCheckbox
} from "shards-react";

import { Select } from 'antd';

import 'antd/dist/antd.css';


import { getBatches } from "../../../redux/actions/batchActions";
import {
  getClasses,
} from "../../../redux/actions/classActions";

import CustomFileUpload from "../../../components/common/CustomFileUpload";
import folderImage from "../../../images/signin/folder.png";

const QuizForm = (props) => {
  const dispatch = useDispatch();

  const [questionType, setQuestionType] = useState(props.data ? props.data.question_type : "all");
  const [quizType, setQuizType] = useState(props.data ? props.data.quiz_type : "fixed");
  const [quizSubjectType, setQuizSubjectType] = useState(props.data ? props.data.quiz_subject_type : "single");
  const [negativeMarking, setNegativeMarking] = useState(props.data ? props.data.is_negative_marking : false);
  const [partialMarking, setPartialMarking] = useState(props.data ? props.data.is_partial_marking : false);
  const [isPaid, setIsPaid] = useState(props.data ? props.data.is_paid : false);
  const [curClass, setCurClass] = useState(null);
  const [batchList, setBatchList] = useState(null);
  const [curBatch, setCurBatch] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [curSubject, setCurSubject] = useState(null);
  const [subjectList, setSubjectList] = useState(null);

  const auth = useSelector(state => state.auth);
  
  const user = auth.user;

  // Get

  useEffect(() => {
    if(user.institute){
      dispatch(getBatches(user.institute.id));
      dispatch(getClasses(user.institute.id));
    }
  },[dispatch]);

  useEffect(() => {
    setCurClass();
  },[])

  const classList = useSelector(state => state.class);
  const { classes } = classList;

  useEffect(() => {
    if(curClass){
      setBatchList(classes[curClass].batch_name);
      setCurBatch(null);
    }
  },[curClass])

  useEffect(() => {
    if(classes && curClass && classes[curClass] && classes[curClass].id && batchList && curBatch && batchList[curBatch] && batchList[curBatch].id){
      const classID = classes[curClass].id;
      // const classID = 250;
      const batchID = batchList[curBatch].id;
      // const batchID = 221;
      console.log(classID);
      console.log(batchID);
      console.log(`${API}/batch-subjects?class_name=${classID}&batch_name=${batchID}`);
      axios
      .get(`${API}/batch-subjects?class_name=${classID}&batch_name=${batchID}`)
        .then(res => {
          console.log(res.data.results[0].subjects);
          setSubjects(res.data.results[0].subjects);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },[curClass,curBatch])

  let children = [];
  if(subjects){
    children = subjects.map(item => {
      return (
        <option key={item.id} value={item.id}>{item.subject_name}</option>
      )
    })
  }
  
  function handleChange(value) {
    setCurSubject(null);
    setSubjectList(value);
  }

  const item = props.data;

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [showFileName, setShowFileName] = useState(false);

  useEffect(() => {
    if(item){
      setImage(item.quiz_image);
      console.log(image);
      if (item.quiz_image) {
        setImageName(item.quiz_image.substring(40));
      }  
    }
  }, [dispatch, item]);

  return (

    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={(e) => props.submitHandler(e)}>
              <Row form>
                <FormInput
                  className="d-none"
                  id="quizID"
                  value={props.data && props.data.id}
                />
                {!props.data && 
                <>
                  <Col md="6" className="form-group">
                    <label htmlFor="className">Class Name</label>
                    <FormInput
                      className="d-none"
                      id="className"
                      value={classes && curClass && classes[curClass] && classes[curClass].id}
                    />
                    <FormSelect 
                      required
                      id="classNameParent"
                      onChange={(e) => setCurClass(e.target.value)}
                      defaultValue={props.data && props.data.class_name}
                    >
                      <option selected = {!curClass} value="">No value chosen</option>
                      {classes && classes.map((item, index) => {
                        return(
                          <option selected={curClass===index} value={index}>{item.class_name}</option>
                        );
                      }
                      )}
                    </FormSelect>
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="batchName">Batch Name</label>
                    <FormInput
                      className="d-none"
                      id="batchName"
                      value={batchList && curBatch && batchList[curBatch] && batchList[curBatch].id}
                    />
                    <FormSelect 
                      required
                      id="batchNameParent"
                      onChange={(e) => setCurBatch(e.target.value)}
                      defaultValue={props.data && props.data.batch}
                    >
                      <option selected = {!curBatch} value="">No value chosen</option>
                      {batchList && batchList.map((item,index) => {
                        return(
                          <option selected={curBatch===index} value={index}>{item.batch_name}</option>
                        );
                      }
                      )}
                    </FormSelect>
                  </Col>
                </>
                }
                <Col md="6" className="form-group">
                  <label htmlFor="className">Question Type</label>
                  <FormSelect 
                    required
                    id="question_type" 
                    onChange={(e) => setQuestionType(e.target.value)}
                    defaultValue={props.data && props.data.question_type}
                  >
                    <option value="mcq">MCQ</option>
                    <option value="text">Text</option>
                    <option selected value="all">All</option>
                  </FormSelect>
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="quizName">Quiz Name</label>
                  <FormInput
                    required
                    id="quizName"
                    type="name"
                    placeholder="Quiz Name"
                    defaultValue={props.data && props.data.quiz_name}
                  />
                </Col>
                {/* Logo */}
                <Col md="6" className="form-group">
                  <label htmlFor="logo">Quiz logo</label>
                  <CustomFileUpload
                    required
                    filename={imageName}
                    onFileNameChange={e => {
                      setImage(
                        URL.createObjectURL(e.target.files[0])
                      );
                      setShowFileName(false);
                      setImageName(e.target.files[0].name);
                    }}
                  />
                  {image !== null && (
                    <a href={image} target="_blank" rel="noopener noreferrer">
                      <img
                        onError={e => {
                          e.target.src = folderImage;
                          e.target.width = "30";
                          setShowFileName(true);
                        }}
                        className="square ml-3"
                        src={image}
                        alt={imageName}
                        width="60"
                      />
                      {showFileName === true && (
                        <p
                          style={{
                            color: "#000",
                            fontSize: "15px",
                            display: "inline-block"
                          }}
                        >
                          {imageName}
                        </p>
                      )}
                    </a>
                  )}
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="quizType">Quiz Type</label>
                  <FormSelect 
                    required
                    id="quizType" 
                    onChange={(e) => setQuizType(e.target.value)}
                    defaultValue={props.data && props.data.quiz_type}
                  >
                    <option selected value="fixed">Fixed Time</option>
                    <option value="anytime">Any Time</option>
                  </FormSelect>
                </Col>
                {!props.data && 
                <>
                  <Col md="6" className="form-group">
                    <label htmlFor="quizSubjectType">Quiz Subject Type</label>
                    <FormSelect 
                      required
                      id="quizSubjectType"
                      onChange={(e) => setQuizSubjectType(e.target.value)}
                      defaultValue={props.data && props.data.quiz_subject_type}
                    >
                      <option selected value="single">Single Subject</option>
                      <option value="multiple">Multiple Subject</option>
                    </FormSelect>
                  </Col>
                  {quizSubjectType==="multiple" ? 
                    <Col md="6" className="form-group">
                      <label htmlFor="subjectNameMultiple">Subject Name</label>
                      <FormInput
                        className="d-none"
                        id="subjectNameMultiple"
                        value={subjectList && JSON.stringify(subjectList)}
                      />
                      <Select
                        required
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        defaultValue={[]}
                        onChange={handleChange}
                      >
                        {children}
                      </Select> 
                    </Col>
                    :
                    <Col md="6" className="form-group">
                      <label htmlFor="subjectNameSingle">Subject Name</label>
                      <FormInput
                        className="d-none"
                        id="subjectNameSingle"
                        value={subjects && curSubject && subjects[curSubject] && subjects[curSubject].id}
                      />
                      <FormSelect 
                        required
                        id="subjectNameSingleParent"
                        onChange={(e) => setCurSubject(e.target.value)}
                        defaultValue={props.data && props.data.single_subject}
                      >
                        <option selected = {!curSubject} value="">No value chosen</option>
                        {subjects && subjects.map((item, index) => {
                          return(
                            <option selected={curSubject===index} value={index}>{item.subject_name}</option>
                          );
                        }
                        )}
                      </FormSelect>
                    </Col>
                  }
                </>
                }
                {quizType==="fixed" && 
                  <>
                    <Col md="6" className="form-group">
                      <label htmlFor="startTime">Start Time</label>
                      <FormInput
                        required
                        id="startTime"
                        type="time"
                        placeholder="Start Time"
                        defaultValue={props.data && props.data.start_time}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label htmlFor="endTime">End Time</label>
                      <FormInput
                        required
                        id="endTime"
                        type="time"
                        placeholder="End Time"
                        defaultValue={props.data && props.data.end_time}
                      />
                    </Col>
                  </>
                }
                <Col md="6" className="form-group">
                  <label htmlFor="duration">Duration</label>
                  <FormInput
                    required
                    id="duration"
                    type="number"
                    placeholder="Duration (in minutes)"
                    defaultValue={props.data && props.data.duration_minutes}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="date">Date</label>
                  <FormInput
                    required
                    id="date"
                    type="date"
                    placeholder="Date"
                    defaultValue={props.data && props.data.date}
                  />
                </Col>
                {questionType!=="text" && 
                  <>
                    <Col md="3" className="form-group">
                      <FormCheckbox
                        id="isNegativeMarking"
                        checked={negativeMarking}
                        value={negativeMarking}
                        onClick={() => setNegativeMarking(!negativeMarking)}
                      >
                        Negative Marking
                      </FormCheckbox>
                    </Col>
                    <Col md="3" className="form-group">
                      <FormCheckbox
                        id="isPartialMarking"
                        checked={partialMarking}
                        value={partialMarking}
                        onClick={() => setPartialMarking(!partialMarking)}
                      >
                        Partial Marking
                      </FormCheckbox>
                    </Col>
                  </>
                }
                <Col md="3" className="form-group">
                  <FormCheckbox
                    id="isPaid"
                    checked={isPaid}
                    onClick={() => setIsPaid(!isPaid)}
                    value={isPaid}
                  >
                    Paid Quiz
                  </FormCheckbox>
                </Col>
              </Row>
              <Button type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default QuizForm;
