import React, { useEffect, useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Button,
  FormSelect
} from "shards-react";

import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const BatchSubjectForm = props => {
  const classList = useSelector(state => state.class);
  const { classes } = classList;

  const [selectedClassBatch, setSelectedClassBatch] = useState([]);

  function getSelectedBatches(id) {
    if (classes.length !== 0) {
      const arr = classes.filter(classData => (Number)(classData.id) === (Number)(id));
      if (arr.length !== 0) setSelectedClassBatch([...arr[0].batch_name]);
    }
  }

  useEffect(() => {
    if (props.classData !== "") {
      getSelectedBatches(props.classData);
    }
  }, [props.classData]);

  // if (props.classData != "") {
  //   getSelectedBatches(props.classData);
  // }

  const style = {
    backgroundColor: "#e1e5ea",
    margin: "0 4px 4px 0",
    padding: "3px 5px",
    borderRadius: "1%",
    display: "inline-block",
    color: "#000"
  };

  const style1 = {
    padding: "2px",
    cursor: "pointer",
    borderColor: "#e1e5ea"
  };

  return (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={e => props.submitHandler(e)}>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="className">Class</label>
                  <FormSelect
                    id="className"
                    value={props.classData}
                    onChange={e => {
                      props.onClassNameChange(e);
                      getSelectedBatches(e.target.value);
                    }}
                  >
                    <option value="">Select Class...</option>
                    {classes.map(classData => (
                      <option key={classData.id} value={classData.id}>
                        {classData.class_name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col md="12" className="form-group">
                  <label htmlFor="batchName">Batch</label>
                  <FormSelect
                    id="batchName"
                    value={props.batchData}
                    onChange={e => {
                      props.onBatchNameChange(e);
                    }}
                  >
                    <option value="">Select Batch...</option>
                    {selectedClassBatch.length !== 0 &&
                      selectedClassBatch.map(batch => (
                        <option key={batch.id} value={batch.id}>
                          {batch.batch_name}
                        </option>
                      ))}
                  </FormSelect>
                </Col>

                <Col md="12" className="form-group">
                  <label htmlFor="subjectNames" style={{ width: "100%" }}>
                    Subjects
                  </label>
                  {props.selectedSubjects.map(subject => (
                    <p style={style} key={subject.id}>
                      {subject.subject_name}
                      <span
                        style={style1}
                        onClick={() => props.removeSubjects(subject.id)}
                      >
                        <i className="fas fa-times-circle"></i>
                      </span>
                    </p>
                  ))}
                  <FormSelect
                    id="subjectNames"
                    onChange={e => {
                      props.addSubjects(e.target.value);
                    }}
                  >
                    <option value="">Select Subjects...</option>
                    {props.subjectArray.map(subject => (
                      <option key={subject.id} value={subject.id}>
                        {subject.subject_name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>

              <Button type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default withRouter(BatchSubjectForm);
