import React from "react";
import { Nav } from "shards-react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import SidebarNavItem from "./SidebarNavItem";
import { FluxStore } from "../../../flux";


class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    const user = this.props.auth.user;

    const items = FluxStore.getSidebarItems().filter((item, idx) => 
      (!item.protected || user.roles===1)
    )
    this.state = {
      navItems: items
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    FluxStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    FluxStore.removeChangeListener(this.onChange);
  }

  onChange() {
    const user = this.props.auth.user;
    const items = FluxStore.getSidebarItems().filter((item, idx) => 
      (!item.protected || user.roles===1)
    )
    this.setState({
      ...this.state,
      // navItems: FluxStore.getSidebarItems()
      navItems: items
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(SidebarNavItems));