import { API } from "../../backend";
import axios from "axios";
import { STUDENT_LOADED, STUDENT_LOADING, SET_STUDENT } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getStudents = (instituteId) => async dispatch => {
  dispatch(studentLoading());
  axios
    .get(`${API}/session-student?id=&institute=${instituteId}&batch_name=&class_name=`)
    .then(res => {
      const students = res.data.results;
      dispatch(setStudent(students,null, null));
      dispatch(studentLoaded());
    })
    .catch(err => {
      dispatch(studentLoaded());
    });
};

export const setStudent = (subjects, mobile,successCreate) => {
  return {
    type: SET_STUDENT,
    payload: subjects,
    mobile: mobile,
    successCreate: successCreate
  };
};

export const createStudent = (studentData,instituteId) => async dispatch => {
  try {
    dispatch(studentLoading());
    await axios.post(`${API}/add-student`, studentData);
    dispatch(getStudents(instituteId));
    dispatch(setStudent([], null, true));
    dispatch(studentLoaded());
  } catch (error) {
    dispatch(studentLoaded());
    dispatch(
      setStudent(
        [],
        error.response.data.mobile,
        error.response.data.username,
        null
      )
    );
  }
};

export const studentLoading = () => {
  return {
    type: STUDENT_LOADING
  };
};

export const studentLoaded = () => {
  return {
    type: STUDENT_LOADED
  };
};

export const deleteStudent = (id,instututeId,userId) => async dispatch => {
  try {
    dispatch(studentLoading());
    await axios.delete(`${API}/update-student/${id}`);
    await axios.delete(`${API}/usercreate/${userId}`);
    dispatch(getStudents(instututeId));
    dispatch(studentLoaded());
  } catch (error) {
    dispatch(studentLoaded());
  }
};

export const editStudent = (id,studentData,instituteId) => async dispatch => {
  try {
    dispatch(studentLoading());
    await axios.put(`${API}/update-student/${id}`, studentData); 
    dispatch(getStudents(instituteId));
    dispatch(studentLoaded());
  } catch (error) {
    dispatch(studentLoaded());
  }
};
