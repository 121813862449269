import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Row,
  Col,
  CardTitle,
  FormInput
} from "shards-react";
import "antd/dist/antd.css";

import Parser from "html-react-parser";

import PageTitle from "../../../components/common/PageTitle";
import Pagination from "../../../components/common/Pagination";

import {
  getQuizSubmissions,
  getQuizSubmissionsByStudent,
  updateMarks
} from "../../../redux/actions/quizSubmissionsActions";
import { getQuestions } from "../../../redux/actions/questionActions";

import folderImage from "../../../images/signin/folder.png";
import axios from "axios";
import { API } from "../../../backend";

const StudentQuizSubmission = props => {
  const dispatch = useDispatch();
  //here item is referring to Student
  const quizId = props.match.params.id;

  const [view, setView] = useState("StudentList");

  const quizSubmissionsList = useSelector(state => state.quizSubmission);
  const { loading, submissions } = quizSubmissionsList;

  const auth = useSelector(state => state.auth);
  const session = useSelector(state => state.session);
  const user = auth.user;

  const [quizName,setQuizName] = useState("");

  // get
  useEffect(() => {
    if (user && user.institute && session && session.curSession && quizId) {
      dispatch(
        getQuizSubmissions(session.curSession, user.institute.id, quizId)
      );
      axios.get(`${API}/quiz/${quizId}`)
      .then(res=> {
        setQuizName(res.data.quiz_name)
      })
    }
  }, [dispatch, quizId, user, session.curSession]);

  let studentSubmitted = [];
  if (submissions.length !== Number(0)) {
    var set = new Set();
    submissions.map(data => {
      const obj = {
        id: data.user.id,
        name: data.user.full_name,
      };
      set.add(JSON.stringify(obj));
      return null;
    });
  }

  if (set && set.size !== 0) {
    for (let item of set) {
      studentSubmitted.push(JSON.parse(item));
    }
  }

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = studentSubmitted.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  //get questions of selected quiz
  const questionList = useSelector(state => state.question);
  const { questions } = questionList;

  const [studentId,setStudentId] = useState(null);

  //load questions
  useEffect(()=>{
    dispatch(getQuestions(quizId));
  },[dispatch])

  //total marks
  let totalMarks = 0;
  if(questions.length !==0 ){
    questions.map((x)=> {
      totalMarks =  Number(x.max_marks)+totalMarks;
      return null;
    })
  }

  const showStudentAnswers = student => {
    setStudentId(student.id)
    dispatch(
      getQuizSubmissionsByStudent(
        session.curSession,
        user.institute.id,
        quizId,
        student.id
      )
    );
    setView("StudentAnswer");
  };

  //give marks
  const marksHandler = (questionId,mark) => {
    const marks = {marks_obtained:mark}
    dispatch(updateMarks(questionId,studentId,marks));
  }

  //students marks
  function studentMarks(userId) {
    let marks=0;
    submissions.map(x => {
      if(x.user.id === userId)
        marks=marks + x.marks_obtained;
      return null;  
    })
    return marks;
  }


  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={quizName}
          subtitle="Students Submission"
          className="text-sm-left mb-3"
        />
        {view === "StudentList" ? (
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              props.history.push(`/quiz-submission`); //send to quiz list
            }}
            className="ml-auto mb-3"
          >
            Back
          </Button>
        ) : (
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              setView("StudentList"); //send to student list
              dispatch(
                getQuizSubmissions(
                  session.curSession,
                  user.institute.id,
                  quizId
                )
              );
            }}
            className="ml-auto mb-3"
          >
            Back
          </Button>
        )}
      </Row>

      <Row>
        {/* Sutudents List */}
        {view === "StudentList" ? (
          <Col lg="8" className="mb-4" style={{ textAlign: "center" }}>
            <Card small className="mb-4 " style={{ textAlign: "left" }}>
              <CardHeader className="border-bottom">
                <h6 className="m-0">STUDENTS SUBMITTED</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Students
                      </th>
                      <th scope="col" className="border-0">
                        Marks
                      </th>
                      <th scope="col" className="border-0">
                        Submissions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    { loading === false &&
                      Array.isArray(currentItems) &&
                      currentItems.map((data, index) => (
                        <tr key={index}>
                          <td>
                            {index + 1 + itemsPerPage * (currentPage - 1)}.
                          </td>
                          <td>{data.name}</td>
                          <td>{studentMarks(data.id)}/{totalMarks}</td>
                          <td>
                            <Button
                              type="primary"
                              size="small"
                              className="mx-1"
                              onClick={() => showStudentAnswers(data)}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            {studentSubmitted !== undefined &&
              studentSubmitted.length > itemsPerPage && (
                <Pagination
                  paginate={paginate}
                  currentPage={currentPage}
                  totalItems={studentSubmitted.length}
                  itemsPerPage={itemsPerPage}
                />
              )}
          </Col>
        ) : (
          <>
            {questions.map((data, index) => {
              return (
                <Col md="12" key={index} className="mb-3">
                  <Card>
                    <CardTitle className="border-bottom d-flex flex-row px-2">
                      <span className="mr-auto ml-2 mt-3 mb-2">
                        Question {index + 1}
                      </span>
                      <span className="mr-0 mt-3 mb-2 d-flex flex-row">
                        marks:
                          <FormInput
                            type="number"
                            id="marks"
                            placeholder="marks"
                            className="mx-2"
                            size="sm"
                            style={{width:"30%"}}
                            defaultValue={submissions.filter(x => (x.question.id === data.id && x.user.id === studentId)).length!==0 ? submissions.filter(x => (x.question.id === data.id && x.user.id === studentId))[0].marks_obtained : 0 }
                            onChange={(e)=>submissions.filter(x => (x.question.id === data.id && x.user.id === studentId)).length!==0 && marksHandler(data.id,Math.min(e.target.value,data.max_marks))}
                          />{"/"}
                        {data.max_marks}
                      </span>
                      
                    </CardTitle>
                    <CardBody className="pt-0 question-card">
                      {Parser(data.question)}
                      {data.question_type === "mcq" ||
                      data.question_type === "multiplecorrect" ? (
                        <Row className="pt-0 mt-0">
                          <Col md="6">(A) {data.option_a}</Col>
                          <Col md="6">(B) {data.option_b}</Col>
                          <Col md="6">(C) {data.option_c}</Col>
                          <Col md="6">(D) {data.option_d}</Col>
                          <Col md="12" className="mt-2">
                            <span className="head">• Answer - </span>
                            {loading === false &&
                              submissions.map(
                                (x,index) =>
                                  x.question.id === data.id && (
                                    <span key={index}>
                                      {x.answer_a && "A"} {x.answer_b && "B"}{" "}
                                      {x.answer_c && "C"} {x.answer_d && "D"}
                                    </span>
                                  )
                              )}
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {loading === false &&
                            submissions.map(
                              (x,index) =>
                                x.question.id === data.id && (
                                  <span key={index}>
                                    <Row className="pt-0 mt-0">
                                      <Col md="12">
                                        <span className="head">
                                          • Answer -{" "}
                                        </span>
                                        {x.answer_text &&
                                            Parser(x.answer_text)}
                                      </Col>
                                    </Row>
                                    {x && x.answer_file && (
                                      <a
                                        href={x.answer_file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          onError={e => {
                                            e.target.src = folderImage;
                                            e.target.width = "30";
                                          }}
                                          style={{ maxWidth: "100%" }}
                                          src={x.answer_file}
                                          alt="Solution file"
                                        ></img>
                                        {x.answer_file.substring(
                                          x.answer_file.length - 3
                                        ) !== "png" &&
                                          x.answer_file.substring(
                                            x.answer_file.length - 3
                                          ) !== "jpg" &&
                                          x.answer_file.substring(
                                            x.answer_file.length - 4
                                          ) !== "jpeg" &&
                                          x.answer_file.substring(
                                            x.answer_file.length - 4
                                          ) !== "jfif" &&
                                          x.answer_file.substring(
                                            x.answer_file.length - 3
                                          ) !== "gif" && (
                                            <p
                                              style={{
                                                color: "#000",
                                                fontSize: "15px",
                                                display: "inline-block"
                                              }}
                                            >
                                              Solution file
                                            </p>
                                          )}
                                      </a>
                                    )}
                                  </span>
                                )
                            )}
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </Container>
  );
};

export default withRouter(StudentQuizSubmission);
