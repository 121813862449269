import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";

import { withRouter } from "react-router-dom";

const DummyForm = (props) => {

  return (
    <ListGroup>
      <ListGroupItem>
        <Row>
          <Col>
            <Form onSubmit={(e) => props.submitHandler(e)}>
              <Row>
                <Col md="12" className="form-group">
                  <label htmlFor="label">Label</label>
                  <FormInput
                    id="label"
                    type="name"
                    placeholder="Placeholder"
                    // value={props.data.name}
                  />
                </Col>
              </Row>
              <Button type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )
  
};

export default withRouter(DummyForm);