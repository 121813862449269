import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container,
  FormRadio
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import PopUp from "../../components/common/PopUp";
import profileAvatar from "../../images/signin/profile_avatar.jpg";
import { DeleteOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from "react-redux";
import { editProfile, getProfile } from "../../redux/actions/profileActions";

const Profile = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState(null);
  const [gender, setGender] = useState(null);

  const userProfile = useSelector(state => state.profile);
  const {profile} = userProfile

  const auth = useSelector(state => state.auth);
  const user = auth.user;

  useEffect(() => {
    if(auth && user){
        dispatch(getProfile(user.id))
    }
  }, [dispatch, auth, user]);

  useEffect(()=>{
    if(profile){
      setEmail(profile.email);
      setMobile(profile.mobile);
      setFullName(profile.full_name)
      if(profile.image!==undefined){
        setFileLink("https://api.zixwer.com/"+profile.image)
      }
      setGender(profile.gender)
    }
  },[profile])

  const editHandler = async e => {
    var data = new FormData();
    data.append("email", email);
    data.append("full_name", fullName);
    data.append("gender", gender);
    if(fileName !== ""){
        data.append("image", fileName);
    }
    else {
      data.append("image", null);
    }
    if(password !== null && password !== ""){
      data.append("password",password);
    }
    dispatch(editProfile(data, user.id));
    showPopUp("profile Updated Successfully!");
  };

  //popup
  const [popMessage, setPopMessage] = useState("");

  const showPopUp = message => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  };

  //for file upload
  const [fileLink, setFileLink] = useState(null);
  const [fileName, setFileName] = useState("");


  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage} />
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="User Profile"
          subtitle="overview"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Details */}
      <Row>
        <Col lg="3">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Profile Image</h6>
            </CardHeader>

                <ListGroup flush>
                  <ListGroupItem className="px-4 text-center">
                    <div className="mb-3 mx-auto">
                      <img
                        className="rounded-circle"
                        src={
                          fileLink == null
                            ? profileAvatar
                            : fileLink
                        }
                        alt={fileName.name}
                        width="110"
                        height="110"
                      />
                    </div>

                <span className="text-muted d-block mb-2">
                  {fileName.name}
                </span>
                <div className="mb-3">
                  <input
                    type="file"
                    className="file"
                    id="file"
                    hidden
                    accept="image/*"
                    onChange={e => {
                      setFileLink(URL.createObjectURL(e.target.files[0]));
                      setFileName(e.target.files[0]);
                    }}
                  />

                  <Button theme="primary" className="ml-auto circle-btn my-auto">
                    <label
                      htmlFor="file"
                      style={{margin:"0",padding:"0"}}
                    >
                        <UploadOutlined className="icon"/>
                    </label>
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="circle-btn my-auto"
                    theme="danger"
                    onClick={() => {
                      setFileLink(null);
                      setFileName("");
                    }}
                    style={{ padding: "9px", marginBottom: "3px" }}
                  >
                    <DeleteOutlined className="icon"/>
                  </Button>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>

        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Account Details</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form onSubmit={e => editHandler(e)}>
                      <Row form>
                         {/* Mobile Number */}
                         <Col md="6" className="form-group">
                          <label htmlFor="phonenumber">Mobile Number</label>
                          <FormInput
                            disabled
                            type="number"
                            id="mobile"
                            placeholder="Mobile Number"
                            value={mobile||""}
                            onChange={e => setMobile(e.target.value)}
                          />
                        </Col>
                        {/* Email */}
                        <Col md="6" className="form-group">
                          <label htmlFor="email">Email</label>
                          <FormInput
                            type="email"
                            id="email"
                            placeholder="Email Address"
                            autoComplete="email"
                            value={email||""}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* fULL NAME */}
                        <Col md="6" className="form-group">
                          <label htmlFor="fullName">Name</label>
                          <FormInput
                            id="fullName"
                            type="text"
                            placeholder="Name"
                            value={fullName||""}
                            onChange={e => setFullName(e.target.value)}
                          />
                        </Col>
                        {/* password */}
                        <Col md="6" className="form-group">
                          <label htmlFor="password">Change Password</label>
                          <FormInput
                            id="password"
                            placeholder="Password"
                            value={password||""}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* Gender */}
                        <Col md="6" className="form-group">
                         <label htmlFor="gender" style={{width:"100%"}}>Gender</label>
                          <FormRadio
                            inline
                            checked={gender === "male"}
                            onChange={() => {
                              setGender("male");
                            }}
                          >Male</FormRadio>
                          <FormRadio
                            inline
                            checked={gender === "female"}
                            onChange={() => {
                              setGender("female");
                            }}
                          >Female</FormRadio>
                        </Col>
                      </Row>
                      <Button theme="accent" type="submit">
                        Update
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Profile);
