import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "shards-react";


import 'antd/dist/antd.css';


import PageTitle from "../../../components/common/PageTitle";

import "../../../assets/quickQuiz.css";

import { getQuiz } from "../../../redux/actions/quickQuizActions";
import OMR from "./OMR";

const QuickQuizPaper = (props) => {

  const quizID = props.match.params.id;
  console.log(props.match.params.id);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuiz(quizID))
  },[])

  const quiz = useSelector(state => state.quickQuiz);

  const item = quiz.curQuickQuiz;

  console.log(item);

  return (
    <Container fluid className="main-content-container px-4">
     {/* Page Header */}
     <Row noGutters className="page-header py-4">
       <PageTitle
         title="Quick Quiz"
         subtitle="Quick Quiz"
         className="text-sm-left mb-3"
       />
     </Row>

     <Row>
        <>
          <Col lg="7" className="mb-4">
            <Card className="p-1">
              <object data={item && item.quiz_image} type="application/pdf" className="pdf">
                  alt : <a href="test.pdf">test.pdf</a>
              </object>
            </Card>
          </Col>

          <Col lg="5">
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{item && item.quiz_name} Quick Quiz Details</h6>
              </CardHeader>
              <CardBody>
              {item && 
                <OMR count={item.no_of_question} filled={item.omr_answer} displayOnly={true}/>
              }
              </CardBody>
            </Card>
          </Col>
        </>
      </Row>
     </Container>
  );
};

export default withRouter(QuickQuizPaper);