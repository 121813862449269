import { API } from "../../backend";
import axios from "axios";
import { SUBJECT_LOADED, SUBJECT_LOADING, SET_SUBJECT } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getSubjects = instituteId => async dispatch => {
  dispatch(subjectLoading());
  axios
    .get(`${API}/subjects?id=&institute=${instituteId}`)
    .then(res => {
      const subjects = res.data.results;
      dispatch(setSubject(subjects));
      dispatch(subjectLoaded());
    })
    .catch(err => {
      dispatch(subjectLoaded());
    });
};

export const setSubject = subjects => {
  return {
    type: SET_SUBJECT,
    payload: subjects
  };
};

export const createSubject = (subjectData, instituteId) => async dispatch => {
  try {
    dispatch(subjectLoading());
    await axios.post(`${API}/subjects`, subjectData);
    dispatch(getSubjects(instituteId));
    dispatch(subjectLoaded());
  } catch (error) {
    dispatch(subjectLoaded());
  }
};

export const subjectLoading = () => {
  return {
    type: SUBJECT_LOADING
  };
};

export const subjectLoaded = () => {
  return {
    type: SUBJECT_LOADED
  };
};

export const deleteSubject = (id, instituteId) => async dispatch => {
  try {
    dispatch(subjectLoading());
    await axios.delete(`${API}/subjects/${id}`); //delete subject
    dispatch(getSubjects(instituteId));
    dispatch(subjectLoaded());
  } catch (error) {
    dispatch(subjectLoaded());
  }
};

export const editSubject = (subjectData, instituteId) => async dispatch => {
  try {
    dispatch(subjectLoading());
    await axios.put(`${API}/subjects/${subjectData.id}`, subjectData); //update subject
    dispatch(getSubjects(instituteId));
    dispatch(subjectLoaded());
  } catch (error) {
    dispatch(subjectLoaded());
  }
};
