import { API } from "../../backend";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { SET_CURRENT_USER, USER_LOADING, USER_LOADED } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

// Login - get user token
export const loginUser = (userData, history) => dispatch => {
  axios
    .post(`${API}/login/`, userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userData", JSON.stringify(res.data));
      // Set token to Auth header
      setAuthToken(localStorage.getItem("jwtToken"));
      axios
        .get(`${API}/current_user`)
        .then(res => {
          // Set current user
          dispatch(setCurrentUser(res.data));
          // login message on succes
          toast("Logged in Successfully", { type: "success" });
          history.push("/"); // re-direct to home on successful login
          // set loading to false
          dispatch(setUserLoaded());
        })
        .catch(err => {
          // toast(err.response.data.detail, { type: "error" });
          dispatch(setUserLoaded());
        });
    })
    .catch(err => {
      toast(err.response.data.detail, { type: "error" });
      dispatch(setUserLoaded());
    });
};

export const setUser = (token) => dispatch => {
  axios
  .get(`${API}/current_user`)
  .then(res => {
    dispatch(setCurrentUser(res.data));
    dispatch(setUserLoaded());
  })
  .catch(err => {
    // toast(err.response.data.detail, { type: "error" });
    dispatch(setUserLoaded());
  });  
}

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// User loading
export const setUserLoaded = () => {
  return {
    type: USER_LOADED
  };
};

// Log user out
export const logoutUser = history => dispatch => {
  // Remove token from local storage
  localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  toast("Logged out Successfully", { type: "warning" });
  if (history) {
    history.push("/");
  }
};
