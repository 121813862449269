import { SESSION_LOADED, SESSION_LOADING, SET_SESSION, SET_CURRENT_SESSION } from "../actions/types";

const initialState = {
  sessions: [],
  curSession: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SESSION_LOADING:
      return {
        ...state,
        loading: true
      };
    case SESSION_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_SESSION:
      return {
        ...state,
        sessions: action.payload
      };
    case SET_CURRENT_SESSION:
      return {
        ...state,
        curSession: action.payload
      };
    default:
      return state;
  }
}
