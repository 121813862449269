import { API } from "../../../backend";
import axios from "axios";
import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button,
  FormCheckbox
} from "shards-react";

import { getBatches } from "../../../redux/actions/batchActions";
import {
  getClasses
} from "../../../redux/actions/classActions";

import CustomFileUpload from "../../../components/common/CustomFileUpload";
import folderImage from "../../../images/signin/folder.png";
import OMR from "./OMR";

const QuizForm = (props) => {

  const dispatch = useDispatch();

  const item = props.data;

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [showFileName, setShowFileName] = useState(false);
  const [negativeMarking, setNegativeMarking] = useState(props.data ? props.data.is_negative_marking : false);
  const [partialMarking, setPartialMarking] = useState(props.data ? props.data.is_partial_marking : false);

  useEffect(() => {
    if(item){
      setImage(item.quiz_image);
      console.log(image);
      if (item.quiz_image) {
        setImageName(item.quiz_image.substring(40));
      }  
    }
  }, [dispatch, item]);

  const [curClass, setCurClass] = useState(null);
  const [batchList, setBatchList] = useState(null);
  const [curBatch, setCurBatch] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [curSubject, setCurSubject] = useState(null);
  
  const auth = useSelector(state => state.auth);
  
  const user = auth.user;

  // Get

  useEffect(() => {
    if(user.institute){
      dispatch(getBatches(user.institute.id));
      dispatch(getClasses(user.institute.id));
    }
  },[dispatch]);

  useEffect(() => {
    setCurClass();
  },[])

  const classList = useSelector(state => state.class);
  const { classes } = classList;

  useEffect(() => {
    if(curClass){
      setBatchList(classes[curClass].batch_name);
      setCurBatch(null);
    }
  },[curClass])

  useEffect(() => {
    if(classes && curClass && classes[curClass] && classes[curClass].id && batchList && curBatch && batchList[curBatch] && batchList[curBatch].id){
      const classID = classes[curClass].id;
      // const classID = 250;
      const batchID = batchList[curBatch].id;
      // const batchID = 221;
      console.log(classID, batchID);
      console.log(`${API}/batch-subjects?class_name=${classID}&batch_name=${batchID}`);
      axios
      .get(`${API}/batch-subjects?class_name=${classID}&batch_name=${batchID}`)
        .then(res => {
          console.log(res.data.results[0].subjects);
          setSubjects(res.data.results[0].subjects);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },[curClass,curBatch])

  const [count, setCount] = useState(props.data ? props.data.no_of_question : 5);

  const countHandler = (e) => {
    setCount(e.target.value);
  }

  return (
    <Form className="p-2" onSubmit={(e) => props.submitHandler(e)}>
      <ListGroup>
        <Row>
          <Col lg="8">
            <ListGroupItem>
              <Row>
                <Col>
                  <Row>
                    <Col md="12" className="form-group">
                      <label htmlFor="quizName">Name</label>
                      <FormInput
                        required
                        id="quizName"
                        type="name"
                        placeholder="Quick Quiz Name"
                        defaultValue={props.data && props.data.quiz_name}
                      />
                    </Col>
                    {!props.data &&
                    <>
                      <Col md="6" className="form-group">
                        <label htmlFor="className">Class Name</label>
                        <FormInput
                          className="d-none"
                          id="className"
                          value={classes && curClass && classes[curClass] && classes[curClass].id}
                        />
                        <FormSelect 
                          required
                          id="classNameParent"
                          onChange={(e) => setCurClass(e.target.value)}
                          defaultValue={props.data && props.data.class_name}
                        >
                          <option selected = {!curClass} value="">No value chosen</option>
                          {classes && classes.map((item, index) => {
                            return(
                              <option selected={curClass===index} value={index}>{item.class_name}</option>
                            );
                          }
                          )}
                        </FormSelect>
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="batchName">Batch Name</label>
                        <FormInput
                          className="d-none"
                          id="batchName"
                          value={batchList && curBatch && batchList[curBatch] && batchList[curBatch].id}
                        />
                        <FormSelect 
                          required
                          id="batchNameParent"
                          onChange={(e) => setCurBatch(e.target.value)}
                          defaultValue={props.data && props.data.batch}
                        >
                          <option selected = {!curBatch} value="">No value chosen</option>
                          {batchList && batchList.map((item,index) => {
                            return(
                              <option selected={curBatch===index} value={index}>{item.batch_name}</option>
                            );
                          }
                          )}
                        </FormSelect>
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="subjectNameSingle">Subject Name</label>
                        <FormInput
                          className="d-none"
                          id="subjectNameSingle"
                          value={subjects && curSubject && subjects[curSubject] && subjects[curSubject].id}
                        />
                        <FormSelect 
                          required
                          id="subjectNameSingleParent"
                          onChange={(e) => setCurSubject(e.target.value)}
                          defaultValue={props.data && props.data.single_subject}
                        >
                          <option selected = {!curSubject} value="">No value chosen</option>
                          {subjects && subjects.map((item, index) => {
                            return(
                              <option selected={curSubject===index} value={index}>{item.subject_name}</option>
                            );
                          }
                          )}
                        </FormSelect>
                      </Col>
                    </>
                    }
                    <Col md="6" className="form-group">
                      <label htmlFor="question_count">Number of questions</label>
                      <FormInput
                        required
                        id="question_count"
                        type="number"
                        placeholder="Number of questions"
                        min="1"
                        max="50"
                        defaultValue={props.data ? props.data.no_of_question : 5}
                        onChange={(e) => countHandler(e)}
                      />
                    </Col>
                    <>
                      <Col md="6" className="form-group">
                        <label htmlFor="startTime">Start Time</label>
                        <FormInput
                          required
                          id="startTime"
                          type="time"
                          placeholder="Start Time"
                          defaultValue={props.data && props.data.start_time}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="endTime">End Time</label>
                        <FormInput
                          required
                          id="endTime"
                          type="time"
                          placeholder="End Time"
                          defaultValue={props.data && props.data.end_time}
                        />
                      </Col>
                    </>
                    <Col md="6" className="form-group">
                      <label htmlFor="duration">Duration</label>
                      <FormInput
                        required
                        id="duration"
                        type="number"
                        placeholder="Duration (in minutes)"
                        defaultValue={props.data && props.data.duration_minutes}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label htmlFor="date">Date</label>
                      <FormInput
                        required
                        id="date"
                        type="date"
                        placeholder="Date"
                        defaultValue={props.data && props.data.date}
                      />
                    </Col>
                    <>
                      <Col md="3" className="form-group">
                        <FormCheckbox
                          id="isNegativeMarking"
                          checked={negativeMarking}
                          value={negativeMarking}
                          onClick={() => setNegativeMarking(!negativeMarking)}
                        >
                          Negative Marking
                        </FormCheckbox>
                      </Col>
                      <Col md="3" className="form-group">
                        <FormCheckbox
                          id="isPartialMarking"
                          checked={partialMarking}
                          value={partialMarking}
                          onClick={() => setPartialMarking(!partialMarking)}
                        >
                          Partial Marking
                        </FormCheckbox>
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="marks">Total Marks</label>
                        <FormInput
                          required
                          id="marks"
                          type="number"
                          placeholder="Total marks"
                          defaultValue={props.data && props.data.total_marks}
                        />
                      </Col>
                    </>
                    {/* Logo */}
                    <Col md="12" className="form-group">
                      <label htmlFor="logo">Question File</label>
                      <CustomFileUpload
                        filename={imageName}
                        accept=".pdf"
                        onFileNameChange={e => {
                          setImage(
                            URL.createObjectURL(e.target.files[0])
                          );
                          setShowFileName(false);
                          setImageName(e.target.files[0].name);
                        }}
                      />
                      {image !== null && (
                        <a href={image} target="_blank" rel="noopener noreferrer">
                          <img
                            onError={e => {
                              e.target.src = folderImage;
                              e.target.width = "30";
                              setShowFileName(true);
                            }}
                            className="square ml-3"
                            src={image}
                            alt={imageName}
                            width="60"
                          />
                          {showFileName === true && (
                            <p
                              style={{
                                color: "#000",
                                fontSize: "15px",
                                display: "inline-block"
                              }}
                            >
                              {imageName}
                            </p>
                          )}
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Button type="submit">Submit</Button>
              </Col>
            </Row>
            </ListGroupItem>
          </Col>
          <Col lg="4">
            <ListGroupItem>
              <OMR count={count} filled={props.data && props.data.omr_answer}/>
            </ListGroupItem>
          </Col>
        </Row>
      </ListGroup>
    </Form>
  )
  
};

export default withRouter(QuizForm);