import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";

import {
  Row,
  Col,
  FormInput,
  FormCheckbox,
} from "shards-react";

import "../../../assets/quickQuiz.css";

const OMR = (props) => {

  const [count, setCount] = useState(0);

  useEffect(() => {
    if(!props.count){
      setCount(0);
    }
    else{
      if(props.count<0){
        setCount(0);
      }
      else if(props.count>50){
        setCount(50);
      }
      else{
        setCount(parseInt(props.count));
      }  
    }
  }, [props])

  const [correct, setCorrect] = useState([]);
  const [re, setRe] = useState(false);
  const [finalAnswers, setFinalAnswers] = useState({});
  const [tmp, setTmp] = useState([]);

  let answers = new Array(count+1);
  useEffect(() => {
    if(props.filled){
      const json = (JSON.parse(props.filled));
      Object.keys(json).map((key, index) => {
        return (
          answers[index+1] = json[key]
        );
      });
      setCorrect(answers);
      setFinalAnswers(answers.slice(1));
    }
    else{
      setCorrect(answers);
    }
  },[props]);

  const changeHandler = (e,correctAnswer,index) => {
    e.preventDefault();
    if(!props.displayOnly){
      let newAnswer = correct;
      newAnswer[index] = correctAnswer;
      setCorrect(newAnswer);
      setRe(!re);
      let answers = {};
      correct.map((data,index) => {
        return(
          answers[index] = data
        );
      })
      setFinalAnswers(answers);
    }
  }

  useEffect(() => {
    let tmp1 = new Array(count).fill("");
    setTmp(tmp1);
  }, [count])

  console.log("FinalAnswers : ", finalAnswers);

  return (
    <Row>
      <Col>
        <FormInput
          className="d-none"
          id="answers"
          value={finalAnswers && JSON.stringify(finalAnswers)}
        />
        <Row>
        <Col md="12" className="form-group d-flex OMR">
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                Q
              </th>
              <th scope="col" className="border-0">
                A
              </th>
              <th scope="col" className="border-0">
                B
              </th>
              <th scope="col" className="border-0">
                C
              </th>
              <th scope="col" className="border-0">
                D
              </th>
            </tr>
          </thead>
          <tbody>
            {tmp && tmp.map((data, index) => {
              return (
                <tr className="my-0 py-0">
                  <td>{index+1}</td>
                  <>
                    <td>
                      <FormCheckbox
                        id="answer_a"
                        value="A"
                        checked={correct[index+1]==="A"}
                        onChange={(e) => changeHandler(e,"A",index+1)}
                      >
                      </FormCheckbox>
                    </td>
                    <td>
                      <FormCheckbox
                        id="answer_b"
                        value='B'
                        checked={correct[index+1]==='B'}
                        onChange={(e) => changeHandler(e,'B',index+1)}
                      >
                      </FormCheckbox>
                    </td>
                    <td>
                      <FormCheckbox
                        id="answer_c"
                        value='C'
                        checked={correct[index+1]==='C'}
                        onChange={(e) => changeHandler(e,'C',index+1)}
                      >
                      </FormCheckbox>
                    </td>
                    <td>
                      <FormCheckbox
                        id="answer_d"
                        value='D'
                        checked={correct[index+1]==='D'}
                        onChange={(e) => changeHandler(e,'D',index+1)}
                      >
                      </FormCheckbox>
                    </td>
                  </>
                </tr>
              );
            })
          }
          </tbody>
        </table>
        </Col>
        </Row>
      </Col>
    </Row>
  )
  
};

export default withRouter(OMR);