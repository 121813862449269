import { API } from "../../backend";
import axios from "axios";
import { CLASS_LOADED, CLASS_LOADING, SET_CLASS } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getClasses = (instituteId) => async dispatch => {
  dispatch(classLoading());
  axios
    .get(`${API}/classes?id=&institute=${instituteId}`)
    .then(res => {
      const classes = res.data.results;
      dispatch(setClasses(classes));
      dispatch(classLoaded());
    })
    .catch(err => {
      dispatch(classLoaded());
    });
};

export const setClasses = classes => {
  return {
    type: SET_CLASS,
    payload: classes
  };
};

export const classLoading = () => {
  return {
    type: CLASS_LOADING
  };
};

export const classLoaded = () => {
  return {
    type: CLASS_LOADED
  };
};

export const createClass = (classData,instituteId) => async dispatch => {
  try {
    dispatch(classLoading());
    await axios.post(`${API}/classes`, classData);
    dispatch(getClasses(instituteId));
    dispatch(classLoaded());
  } catch (error) {
    dispatch(classLoaded());
  }
};

export const deleteClass = (id,instituteId) => async dispatch => {
  try {
    dispatch(classLoading());
    await axios.delete(`${API}/classes/${id}`); //delete class
    dispatch(getClasses(instituteId));
    dispatch(classLoaded());
  } catch (error) {
    dispatch(classLoaded());
  }
};

export const editClass = (classData,instituteId) => async dispatch => {
  try {
    dispatch(classLoading());
    await axios.put(`${API}/classes/${classData.id}`, classData); //update class
    dispatch(getClasses(instituteId));
    dispatch(classLoaded());
  } catch (error) {
    dispatch(classLoaded());
  }
};
