import React from "react";

const CustomFileUpload = ({ filename, onFileNameChange, accept }) => (
  <div className="custom-file mb-3">
    <input
      type="file"
      className="file"
      accept={accept ? accept : ""}
      required = {filename===null ? true : false}
      id="file"
      onChange={e => onFileNameChange(e)}
    />
    <label className="custom-file-label" htmlFor="file">
      {filename ? filename : "Choose file..."}
    </label>
  </div>
);

export default CustomFileUpload;
