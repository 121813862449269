import firebase from "firebase";

const config = {
    apiKey: "AIzaSyBww2V48z2LJs76-Dtrt1Is7AJXlTrHzIk",
    authDomain: "zixwer-notifications.firebaseapp.com",
    projectId: "zixwer-notifications",
    storageBucket: "zixwer-notifications.appspot.com",
    messagingSenderId: "268434779162",
    appId: "1:268434779162:web:dd48df22993f4f0da975a3",
    measurementId: "G-MEBFGC05X3"
};

firebase.initializeApp(config);

const messaging = firebase.messaging();
messaging.requestPermission()
  .then(() => {
    console.log("success");
    return messaging.getToken()
  })
  .then(token => {
    console.log("token: ", token);
  })
  .catch(err => {
    console.log(err);
  })

messaging.onMessage(payload => {
    console.log("payload: ", payload);
})

export default firebase;