export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";

export const SET_SESSION = "SET_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const SESSION_LOADED = "SESSION_LOADED";
export const SESSION_LOADING = "SESSION_LOADING";
export const SET_CURRENT_SESSION = "SET_CURRENT_SESSION";

export const SET_QUIZ = "SET_QUIZ";
export const QUIZ_LOADED = "QUIZ_LOADED";
export const QUIZ_LOADING = "QUIZ_LOADING";
export const SET_CURRENT_QUIZ = "SET_CURRENT_QUIZ";

export const SET_QUICK_QUIZ = "SET_QUICK_QUIZ";
export const QUICK_QUIZ_LOADED = "QUICK_QUIZ_LOADED";
export const QUICK_QUIZ_LOADING = "QUICK_QUIZ_LOADING";
export const SET_CURRENT_QUICK_QUIZ = "SET_CURRENT_QUICK_QUIZ";

export const BATCH_LOADED = "BATCH_LOADED";
export const BATCH_LOADING = "BATCH_LOADING";
export const SET_BATCH = "SET_BATCH";

export const SET_QUESTION = "SET_QUESTION";
export const QUESTION_LOADING = "QUESTION_LOADING";
export const QUESTION_LOADED = "QUESTION_LOADED";

export const CLASS_LOADED = "CLASS_LOADED";
export const CLASS_LOADING = "CLASS_LOADING";
export const SET_CLASS = "SET_CLASS";

export const SUBJECT_LOADED = "SUBJECT_LOADED";
export const SUBJECT_LOADING = "SUBJECT_LOADING";
export const SET_SUBJECT = "SET_SUBJECT";

export const INSTITUTE_LOADED = "INSTITUTE_LOADED";
export const INSTITUTE_LOADING = "INSTITUTE_LOADING";
export const SET_INSTITUTE = "SET_INSTITUTE";

export const BATCH_SUBJECT_LOADED = "BATCH_SUBJECT_LOADED";
export const BATCH_SUBJECT_LOADING = "BATCH_SUBJECT_LOADING";
export const SET_BATCH_SUBJECT = "SET_BATCH_SUBJECT";

export const STUDENT_LOADED = "STUDENT_LOADED";
export const STUDENT_LOADING = "STUDENT_LOADING";
export const SET_STUDENT = "SET_STUDENT";

export const PROFILE_LOADED = "PROFILE_LOADED";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const SET_PROFILE = "SET_PROFILE";

export const QUIZ_SUBMISSIONS_LOADED = "QUIZ_SUBMISSIONS_LOADED";
export const QUIZ_SUBMISSIONS_LOADING = "QUIZ_SUBMISSIONS_LOADING";
export const SET_QUIZ_SUBMISSIONS = "SET_QUIZ_SUBMISSIONS";

export const QUICK_QUIZ_SUBMISSIONS_LOADED = "QUICK_QUIZ_SUBMISSIONS_LOADED";
export const QUICK_QUIZ_SUBMISSIONS_LOADING = "QUICK_QUIZ_SUBMISSIONS_LOADING";
export const SET_QUICK_QUIZ_SUBMISSIONS = "SET_QUICK_QUIZ_SUBMISSIONS";

export const QUICK_QUIZ_SUBMISSIONFILES_LOADED = "QUICK_QUIZ_SUBMISSIONFILES_LOADED";
export const QUICK_QUIZ_SUBMISSIONFILES_LOADING = "QUICK_QUIZ_SUBMISSIONFILES_LOADING";
export const SET_QUICK_QUIZ_SUBMISSIONFILES = "SET_QUICK_QUIZ_SUBMISSIONFILES";

