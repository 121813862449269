import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "shards-react";


import 'antd/dist/antd.css';
import {  Avatar } from 'antd';

import PageTitle from "../../components/common/PageTitle";

import "../../assets/quickQuiz.css"

import { getQuizzes } from "../../redux/actions/quickQuizActions";

const QuickQuizSubmission = (props) => {

  const color = ["red","DeepPink","blue","green","Navy","Purple","Gold","OliveDrab","Orange","RosyBrown"];

  const randomColor = (data) => {
    // random function to get an index
    const idx = (data.id*210 + 45 + data.id%13)%10;
    // const idx = 9;
    return color[idx];
  }
  const dispatch = useDispatch();

  const quiz = useSelector(state => state.quickQuiz);
  const auth = useSelector(state => state.auth);
  const session = useSelector(state => state.session);
  
  const items = quiz.quickQuizzes;
  const user = auth.user;


  // Get

  useEffect(() => {
    if(user.institute && user.institute.id && session && session.curSession){
      dispatch(getQuizzes(session.curSession,user.institute.id));
    }
  },[dispatch]);

  

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Quick Quiz Submission"
          subtitle="Quick Quiz Submission"
          className="text-sm-left mb-3"
        />
      </Row>
  
      <Row>
       
           <Col lg="12" className="mb-4">
            <Row className="p-5">
              {items.map((data,index) => 
                <Col key={index} lg="4" md="6" className="mb-4">
                  <Card small className="mb-4">
                    <CardBody className="border-bottom d-flex py-auto">
                      <Avatar 
                        style={{backgroundColor:randomColor(data)}}
                        onClick={() => props.history.push(`/student-quickquiz-submission/${data.id}`)}
                        shape="circle" size={50}
                      >
                        {data.quiz_name[0]}
                      </Avatar>
                      <strong className="my-auto ml-2">
                        {data.quiz_name}
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
      </Row>
    </Container>
  );
};

export default withRouter(QuickQuizSubmission);