import { API } from "../../backend";
import axios from "axios";
import { SET_QUESTION, QUESTION_LOADED, QUESTION_LOADING } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getQuestions = (id) => dispatch => {
  axios
    .get(`${API}/quiz-questions?quiz_name=${id}`)
    .then(res => {
      const quizzes = res.data.results;
      dispatch(setQuestions(quizzes));
      dispatch(questionLoaded());
    })
    .catch(err => {
      dispatch(questionLoaded());
    });
};

export const createQuestion = (data, quizId) => dispatch => {
  axios
    .post(`${API}/quiz-questions`, data)
    .then(res => {
      console.log("success");
      dispatch(getQuestions(quizId));
      dispatch(questionLoaded());
    })
    .catch(err => {
      console.log("err : ", err);
      dispatch(questionLoaded());
    });
};

export const editQuestion = (data, questionId, quizId) => dispatch => {
  axios
    .put(`${API}/quiz-questions/${questionId}`, data)
    .then(res => {
      console.log("success");
      dispatch(getQuestions(quizId));
      dispatch(questionLoaded());
    })
    .catch(err => {
      console.log("err : ", err);
      dispatch(questionLoaded());
    });
};

export const deleteQuestion = (id, quizId) => dispatch => {
  axios
    .delete(`${API}/quiz-questions/${id}`)
    .then(res => {
      dispatch(getQuestions(quizId));
      dispatch(questionLoaded());
    })
    .catch(err => {
      dispatch(questionLoaded());
    });
};

export const setQuestions = questions => {
  return {
    type: SET_QUESTION,
    payload: questions
  };
};

export const questionLoading = () => {
  return {
    type: QUESTION_LOADING
  };
};

export const questionLoaded = () => {
  return {
    type: QUESTION_LOADED
  };
};