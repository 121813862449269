import {
  INSTITUTE_LOADED,
  INSTITUTE_LOADING,
  SET_INSTITUTE
} from "../actions/types";

const initialState = {
  institute: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INSTITUTE_LOADING:
      return {
        ...state,
        loading: true
      };
    case INSTITUTE_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_INSTITUTE:
      return {
        ...state,
        institute: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
