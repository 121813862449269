import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container
} from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import PopUp from "../../components/common/PopUp";
import CustomFileUpload from "../../components/common/CustomFileUpload";

import { useSelector, useDispatch } from "react-redux";
import {
  editInstitute,
  getInstitute
} from "../../redux/actions/instituteActions";

const Institute = () => {
  const dispatch = useDispatch();

  const [instituteName, setInstituteName] = useState("");
  const [instituteEmail, setInstituteEmail] = useState("");
  const [instituteNumber, setInstituteNumber] = useState("");
  const [instituteLogo, setInstituteLogo] = useState(null);
  const [instituteLogoName, setInstituteLogoName] = useState(null);

  const userDetails = useSelector(state => state.auth);
  const { user } = userDetails;
  const { institute } = user;

  const { institute: i } = useSelector(state => state.institute);

  function isEmptyObject(obj) {
    return JSON.stringify(obj) === "{}";
  }

  useEffect(() => {
    if (isEmptyObject(i) && institute) {
      dispatch(getInstitute(institute.id));
    } else {
      setInstituteName(i.institute_name);
      setInstituteEmail(i.email);
      setInstituteNumber(i.mobile_no);
      if (i.logo !== undefined && i.logo != null) {
        setInstituteLogo(i.logo);
        setInstituteLogoName(i.logo.substring(18));
      }
    }
  }, [dispatch, institute, i]);

  const editHandler = async e => {
    setInstituteLogo(null);
    var insData = new FormData();
    insData.append("institute_name", instituteName);
    insData.append("email", instituteEmail);
    insData.append("mobile_no", instituteNumber);
    if(e.target.file.files[0]!==undefined){
      insData.append("logo", e.target.file.files[0]);
    }
    dispatch(editInstitute(insData, institute.id));
    showPopUp("Details Updated Successfully!");
  };

  //popup
  const [popMessage, setPopMessage] = useState("");

  const showPopUp = message => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  };

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage} />
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Institute"
          subtitle="overview"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Details */}
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Institute Details</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form onSubmit={e => editHandler(e)}>
                      <Row form>
                        {/* Institute name */}
                        <Col md="6" className="form-group">
                          <label htmlFor="instituteName">Institute Name</label>
                          <FormInput
                            id="instituteName"
                            placeholder="Institute Name"
                            value={instituteName||""}
                            onChange={e => setInstituteName(e.target.value)}
                          />
                        </Col>
                        {/* Email */}
                        <Col md="6" className="form-group">
                          <label htmlFor="email">Email</label>
                          <FormInput
                            type="email"
                            id="email"
                            placeholder="Email Address"
                            autoComplete="email"
                            value={instituteEmail||""}
                            onChange={e => setInstituteEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* Mobile Number */}
                        <Col md="6" className="form-group">
                          <label htmlFor="phonenumber">Mobile Number</label>
                          <FormInput
                            type="number"
                            id="mobile"
                            placeholder="Mobile Number"
                            value={instituteNumber||""}
                            onChange={e => setInstituteNumber(e.target.value)}
                          />
                        </Col>
                        {/* Logo */}
                        <Col md="6" className="form-group">
                          <label htmlFor="logo">Institute logo</label>
                          <CustomFileUpload
                            filename={instituteLogoName}
                            accept="image/*"
                            onFileNameChange={e => {
                              setInstituteLogo(
                                URL.createObjectURL(e.target.files[0])
                              );
                              setInstituteLogoName(e.target.files[0].name);
                            }}
                          />
                          {instituteLogo !==null &&(
                            <a href={instituteLogo} target="_blank" rel="noopener noreferrer">
                              <img
                                className="square ml-3"
                                src={instituteLogo}
                                alt={instituteLogoName}
                                width="60"
                                height="60"
                              />
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Button theme="accent" type="submit">
                        Update
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Institute);
