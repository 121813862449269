import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import sessionReducer from "./sessionReducer";
import quizReducer from "./quizReducer";
import quickQuizReducer from "./quickQuizReducer";
import batchReducer from "./batchReducer";
import questionReducer from "./questionReducer ";
import classReducer from "./classReducer";
import subjectReducer from "./subjectReducer";
import instituteReducer from "./instituteReducer";
import batchSubjectReducer from "./batchSubjectReducer";
import studentReducer from "./studentReducer";
import profileReducer from "./profileReducer";
import quizSubmissionReducer from "./quizSubmissionReducer";
import quickQuizSubmissionReducer from "./quickQuizSubmissionReducer";
import quickQuizSubmissionFileReducer from "./quickQuizSubmissionFileReducer";

const RootReducer = combineReducers({
  auth: AuthReducer,
  session: sessionReducer,
  quiz: quizReducer,
  quickQuiz: quickQuizReducer,
  batch: batchReducer,
  question: questionReducer,
  class: classReducer,
  subject: subjectReducer,
  institute: instituteReducer,
  batchSubject: batchSubjectReducer,
  student: studentReducer,
  profile:profileReducer,
  quizSubmission:quizSubmissionReducer,
  quickQuizSubmission:quickQuizSubmissionReducer,
  quickQuizSubmissionFile:quickQuizSubmissionFileReducer
});

export default RootReducer;
