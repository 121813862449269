import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Razorpay from "razorpay";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormCheckbox,
} from "shards-react";

import 'antd/dist/antd.css';
import { Popover, Typography, Spin } from 'antd';

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import PopUp from "../../components/common/PopUp";

import { getSessions, createSession, editSession , deleteSession, changeCurrentSession } from "../../redux/actions/sessionActions";
import { toast } from "react-toastify";

const { Title, Text } = Typography;

const Payment = () => {

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const session = useSelector(state => state.session);
  const auth = useSelector(state => state.auth);
  
  const items = session.sessions;
  const user = auth.user;

  // Get

  useEffect(() => {
    dispatch(getSessions());
  },[dispatch]);

  // Pop up
  const [popMessage, setPopMessage] = useState("");

  const showPopUp = (message) => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  }

  // Payment

  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  const RAZORPAY_KEY_ID = process.env.RAZORPAY_KEY_ID;

  async function displayRazorpay() {
    setLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if(!res){
      setLoading(false);
      toast("Razorpay SDK failed to load. Please try again later",{
        type:"error"
      });
      return;
    }
    const result = await axios.get("https://api.zixwer.com/quiz/api/create-subscription");
    if (!result) {
        setLoading(false);
        toast("Server error. Please try again later",{
          type:"error"
        });
        return;
    }
    if (result.data.subscription_status) {
      setLoading(false);
      toast("Already subscribed.", {
        type:"success"
      });
      return;
    }
    const { amount, id: order_id } = result.data.order_details;

    const options = {
        key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: "INR",
        name: "Zixwer",
        description: "Payment for subscription.",
        order_id: order_id,
        handler: async function (response) {
            console.log(response);
            const data = {
                orderCreationId: response.order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
            const url = `https://api.zixwer.com/quiz/api/verify-payment?amount=${amount}&razorpay_payment_id=${data.razorpayPaymentId}&razorpay_order_id=${order_id}&razorpay_signature=${data.razorpaySignature}`
            console.log("res : ", data);
            const result = await axios.get(url);
            if(result.data.valid_payment_signature){
              toast("Payment Success.", {
                type:"success"
              });
            }
            else{
              toast("Payment Failed.", {
                type:"error"
              });
            }
            setLoading(false);
            console.log(result);
        },
        prefill: {
            name: "Test Name",
            email: "testEmail@example.com",
            contact: "1234567890",
        },
        notes: {
            address: "Test Address Corporate Office",
        },
        theme: {  
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoading(false);
  }

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage}/>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Payment"
          subtitle="Payment"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row className="text-center">
        <Col lg={12}>
          <Title level={2}>Get started with the subscription than works for you.</Title>
        </Col>
        <Col lg={2}></Col>
        <Col lg={8}>
          <Title level={4}>Get access to many features like Chat, Quiz, Quick Quiz, Batch management, Classes, Sessions, Institute data visualization along with customer support at just ₹100.</Title>
        </Col>
        <Col lg={12} className="mt-2">
          <Spin spinning={loading} delay={500}>
            <Button size="lg" outline squared onClick={() => displayRazorpay()}>Subscribe</Button>
          </Spin>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Payment);