import { QUIZ_SUBMISSIONS_LOADED, QUIZ_SUBMISSIONS_LOADING, SET_QUIZ_SUBMISSIONS } from "../actions/types";

const initialState = {
  submissions: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case QUIZ_SUBMISSIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case QUIZ_SUBMISSIONS_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_QUIZ_SUBMISSIONS:
      return {
        ...state,
        submissions: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
