import { QUICK_QUIZ_SUBMISSIONS_LOADED, QUICK_QUIZ_SUBMISSIONS_LOADING, SET_QUICK_QUIZ_SUBMISSIONS } from "../actions/types";

const initialState = {
  submissions: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case QUICK_QUIZ_SUBMISSIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case QUICK_QUIZ_SUBMISSIONS_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_QUICK_QUIZ_SUBMISSIONS:
      return {
        ...state,
        submissions: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
