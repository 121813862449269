import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "shards-react";

import 'antd/dist/antd.css';
import { Popover } from 'antd';

import Parser from 'html-react-parser';

import PopUp from "../../../components/common/PopUp";
import PageTitle from "../../../components/common/PageTitle";

import QuestionForm from "../components/QuestionForm";
import "../../../assets/question-card.css";

import { getQuestions, deleteQuestion, createQuestion, editQuestion  } from "../../../redux/actions/questionActions";

const Questions = (props) => {

  const dispatch = useDispatch();
  const [addItem,setAddItem] = useState(true);
  const [item,setItem] = useState(null);
  const [view, setView] = useState("list");

  const question = useSelector(state => state.question);
  const session = useSelector(state => state.session);
  const quiz = useSelector(state => state.quiz);
  const auth = useSelector(state => state.auth);
  
  const items = question.questions;
  const user = auth.user;

  const quizId = props.match.params.id;

  // Get

  useEffect(() => {
    dispatch(getQuestions(quizId));
  },[dispatch]);

  // Pop up
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = (id) => {
    setDeleteItemId(id);
    setDeletePopUpVisible(true);
  }  

  const showPopUp = (message) => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  }

  // Delete

  const deleteHandler = async() => {
    dispatch(deleteQuestion(deleteItemId, quizId));
    showPopUp("Question deleted Successfully!");
  }

  // Edit
  const editHandler = (data) => {
    setItem(data);
    setAddItem(false);
    setView("form");
  }

  // Create and Edit

  const submitHandler = (e) => {
    e.preventDefault();
    var data = new FormData();
    if(addItem===true){
      data.append("quiz_name", quizId);
      data.append("subject_name", 1);
      data.append("class_name", quiz.curQuiz.class_name.id);
      data.append("question_type", e.target.question_type.value);
      data.append("question", e.target.question.value);
      if(e.target.question_type.value!=="text"){
        data.append("option_a", e.target.option_a && e.target.option_a.value);
        data.append("option_b", e.target.option_b && e.target.option_b.value);
        data.append("option_c", e.target.option_c && e.target.option_c.value);
        data.append("option_d", e.target.option_d && e.target.option_d.value);  
        data.append("answer_a", e.target.answer_a && e.target.answer_a.value);
        data.append("answer_b", e.target.answer_b && e.target.answer_b.value);
        data.append("answer_c", e.target.answer_c && e.target.answer_c.value);
        data.append("answer_d", e.target.answer_d && e.target.answer_d.value);
      }
      data.append("answer", e.target.answer && e.target.answer.value);
      data.append("solution_text", e.target.solution_text.value);
      data.append("solution_image", e.target.file.files[0]);
      data.append("max_marks", e.target.max_marks.value);
      data.append("status", true);
      data.append("session", session.curSession);
      data.append("institute", user.institute.id);
      dispatch(createQuestion(data,quizId));
      showPopUp("Question Added Successfully!");
    }
    else{
      data.append("question", e.target.question.value);
      if(e.target.option_a){
        data.append("option_a", e.target.option_a && e.target.option_a.value);
        data.append("option_b", e.target.option_b && e.target.option_b.value);
        data.append("option_c", e.target.option_c && e.target.option_c.value);
        data.append("option_d", e.target.option_d && e.target.option_d.value);  
        data.append("answer_a", e.target.answer_a && e.target.answer_a.value);
        data.append("answer_b", e.target.answer_b && e.target.answer_b.value);
        data.append("answer_c", e.target.answer_c && e.target.answer_c.value);
        data.append("answer_d", e.target.answer_d && e.target.answer_d.value);
      }
      data.append("answer", e.target.answer && e.target.answer.value);
      data.append("solution_text", e.target.solution_text.value);
      if(e.target.file.files[0]){
        data.append("solution_image", e.target.file.files[0]);
      }
      data.append("max_marks", e.target.max_marks.value);
      dispatch(editQuestion(data,item.id,quizId));
      setView("list");
      showPopUp("Question edited Successfully!");
    }
    setItem(null);
  }
  
  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }} 
        theme="white" size="sm" className="mx-1"
      >
        Cancel
      </Button>
      <Button 
        onClick={()=>{
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        size="sm" className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage}/>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Question"
          subtitle="Question"
          className="text-sm-left mb-3"
        />
        {view==="form" ? 
          <Button type="primary" size="sm" onClick={() => {setView("list");setItem(null);setAddItem(true)}}  className="ml-auto mb-3">
            Back
          </Button>
          :
          <Button type="primary" size="sm" onClick={() => {setView("form");setItem(null);setAddItem(true)}}  className="ml-auto mb-3">
            New Question
          </Button>
        }
      </Row>

      {/* Questions */}
      <Row>
        {view==="form" ? 
          <Col md="12">
            <QuestionForm submitHandler = {submitHandler} data={item}/>
          </Col>
          :
          <>
            {items.map((data, index) => {
              return (
                <Col md="12" 
                  key={index}
                  className="mb-3"
                >
                  <Card>
                  <CardTitle className="border-bottom d-flex flex-row-reverse px-2">
                    <Popover 
                      content={content} 
                      title="Sure to delete?" 
                      trigger="click" 
                      className="mx-1 my-3"
                      visible={deletePopUpVisible && data.id===deleteItemId}
                    >
                      <Button
                        onClick={() => openDeletePopUp(data.id)} theme="danger" size="sm"
                      >
                        Delete
                      </Button>
                    </Popover>
                    <Button 
                      type="primary" 
                      size="sm" 
                      className="mx-1 my-3"
                      onClick={()=> editHandler(data)}
                    >
                      Edit
                    </Button>
                    <span className="mr-auto ml-2 mt-3">Question {index+1}</span>
                  </CardTitle>
                    <CardBody className="pt-0 question-card">
                      {Parser(data.question)}
                      {(data.question_type==="mcq" || data.question_type==="multiplecorrect") ? 
                        <Row className="pt-0 mt-0">
                          <Col md="6">(A) {data.option_a}</Col>
                          <Col md="6">(B) {data.option_b}</Col>
                          <Col md="6">(C) {data.option_c}</Col>
                          <Col md="6">(D) {data.option_d}</Col>
                          <Col md="12" className="mt-2">
                            <span className="head">• Correct Answer - </span>
                            {data.answer_a && "A"} {data.answer_b && "B"} {data.answer_c && "C"} {data.answer_d && "D"}
                          </Col>
                        </Row>
                        :
                        <Row className="pt-0 mt-0">
                          <Col md="12">
                            <span className="head">• Answer</span>
                            {data.answer && Parser(data.answer)}
                          </Col>
                        </Row>
                      }
                      <Row className="pt-0 mt-0">
                        <Col md="12">
                          <span className="head">• Explanation</span>
                          {Parser(data.solution_text)}
                        </Col>
                      </Row>
                      <img
                        style={{maxWidth:"80vw"}}
                        src={data.solution_image}
                        alt="Solution"
                      >
                      </img>
                    </CardBody>
                  </Card>
                </Col>
              )})
            }
          </>
        }
      </Row>
    </Container>
  );
};

export default withRouter(Questions);
