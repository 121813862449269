import { API } from "../../backend";
import axios from "axios";
import { QUICK_QUIZ_SUBMISSIONFILES_LOADED, QUICK_QUIZ_SUBMISSIONFILES_LOADING, SET_QUICK_QUIZ_SUBMISSIONFILES } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


export const getQuickQuizSubmissionFilesByStudent = (sessionId,instituteId,quizId,userId) => async dispatch => {
  dispatch(quickQuizSubmissionFilesLoading());
  axios
    .get(`${API}/answer-quick-quiz-file?id=&session=${sessionId}&institute=${instituteId}&student=${userId}&quick_quiz__id=${quizId}`)
    .then(res => {
      const submissionFiles = res.data.results;
      dispatch(setQuickQuizSubmissionFiles(submissionFiles));
      dispatch(quickQuizSubmissionFilesLoaded());
    })
    .catch(err => {
      dispatch(quickQuizSubmissionFilesLoaded());
    });
};

export const setQuickQuizSubmissionFiles = submissionFiles => {
  return {
    type: SET_QUICK_QUIZ_SUBMISSIONFILES,
    payload: submissionFiles
  };
};

export const quickQuizSubmissionFilesLoading = () => {
  return {
    type: QUICK_QUIZ_SUBMISSIONFILES_LOADING
  };
};

export const quickQuizSubmissionFilesLoaded = () => {
  return {
    type: QUICK_QUIZ_SUBMISSIONFILES_LOADED
  };
};



export const updateFileMarks = (answerId,marks) => async dispatch => {
  await axios.put(`${API}/answer-quick-quiz-file/${answerId}`,marks)
};