import { API } from "../../backend";
import axios from "axios";
import {  BATCH_LOADED, BATCH_LOADING, SET_BATCH} from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getBatches = (instituteId) => async(dispatch) => {
  dispatch(batchLoading())
  axios
      .get(`${API}/batch?id=&batch_name=&institute=${instituteId}`)
      .then(res => {
        const batches = res.data.results;
        dispatch(setBatches(batches));
        dispatch(batchLoaded());
      })
      .catch(err => {
        dispatch(batchLoaded());
      });
};

export const setBatches = (batches) => {
  return {
    type: SET_BATCH,
    payload: batches
  };
};

export const createBatch = (batchData,instituteId) =>async dispatch => {
  try{
    dispatch(batchLoading())
    await axios.post(`${API}/batch`, batchData)
    dispatch(getBatches(instituteId))
    dispatch(batchLoaded());
  }
  catch(error){
    dispatch(batchLoaded());
  }
};

export const batchLoading = () => {
  return {
    type: BATCH_LOADING
  };
};

export const batchLoaded = () => {
  return {
    type: BATCH_LOADED
  };
};


export const deleteBatch = (id,instituteId) => async(dispatch) =>{
  try {
      dispatch(batchLoading())
      await axios.delete(`${API}/batch/${id}`)      //delete batch
      dispatch(getBatches(instituteId))
      dispatch(batchLoaded())
  } catch (error) {
    dispatch(batchLoaded())
  }
}

export const editBatch = (batchData,instituteId) => async(dispatch) =>{
  try {
      dispatch(batchLoading())
      await axios.put(`${API}/batch/${batchData.id}`,batchData)      //update batch
      dispatch(getBatches(instituteId))
      dispatch(batchLoaded())
  } catch (error) {
      dispatch(batchLoaded())
  }
}