import { API } from "../../backend";
import axios from "axios";
import {
  BATCH_SUBJECT_LOADED,
  BATCH_SUBJECT_LOADING,
  SET_BATCH_SUBJECT
} from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getBatchSubjects = instituteId => async dispatch => {
  dispatch(batchSubjectLoading());
  axios
    .get(
      `${API}/batch-subjects?id=&institute=${instituteId}&class_name=&batch_name=`
    )
    .then(res => {
      const batchSubjects = res.data.results;
      dispatch(setBatchSubjects(batchSubjects));
      dispatch(batchSubjectLoaded());
    })
    .catch(err => {
      dispatch(batchSubjectLoaded());
    });
};

export const setBatchSubjects = batchSubjects => {
  return {
    type: SET_BATCH_SUBJECT,
    payload: batchSubjects
  };
};

export const batchSubjectLoading = () => {
  return {
    type: BATCH_SUBJECT_LOADING
  };
};

export const batchSubjectLoaded = () => {
  return {
    type: BATCH_SUBJECT_LOADED
  };
};

export const createBatchSubject = (
  batchSubject,
  instituteId
) => async dispatch => {
  try {
    dispatch(batchSubjectLoading());
    await axios.post(`${API}/batch-subjects`, batchSubject);
    dispatch(getBatchSubjects(instituteId));
    dispatch(batchSubjectLoaded());
  } catch (error) {
    dispatch(batchSubjectLoaded());
  }
};

export const deleteBatchSubject = (id, instituteId) => async dispatch => {
  try {
    dispatch(batchSubjectLoading());
    await axios.delete(`${API}/batch-subjects/${id}`);
    dispatch(getBatchSubjects(instituteId));
    dispatch(batchSubjectLoaded());
  } catch (error) {
    dispatch(batchSubjectLoaded());
  }
};

export const editBatchSubject = (
  batchSubject,
  id,
  instituteId
) => async dispatch => {
  try {
    dispatch(batchSubjectLoading());
    await axios.put(`${API}/batch-subjects/${id}`, batchSubject);
    dispatch(getBatchSubjects(instituteId));
    dispatch(batchSubjectLoaded());
  } catch (error) {
    dispatch(batchSubjectLoaded());
  }
};
