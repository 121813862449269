import {
  BATCH_SUBJECT_LOADED,
  BATCH_SUBJECT_LOADING,
  SET_BATCH_SUBJECT
} from "../actions/types";

const initialState = {
  batchSubjects: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case BATCH_SUBJECT_LOADING:
      return {
        ...state,
        loading: true
      };
    case BATCH_SUBJECT_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_BATCH_SUBJECT:
      return {
        ...state,
        batchSubjects: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
