import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "../layouts";

// Route Views
import BlogOverview from "../views/BlogOverview";
import InstituteOverview from "../Pages/dashboard/index";
import Sessions from "../Pages/session/index";
import Quiz from "../Pages/quiz/index";
import QuizSubmission from "../Pages/quiz-submission/index";
import QuickQuiz from "../Pages/quick-quiz/index";
import QuickQuizPaper from "../Pages/quick-quiz/components/QuickQuizPaper";
import QuickQuizSubmission from "../Pages/quick-quiz-submission/index";
import Batch from "../Pages/batch/index";
import Class from "../Pages/class/index";
import Subject from "../Pages/subject/index";
import BatchSubject from "../Pages/batchSubject/index";
import Institute from "../Pages/institute/index";
import Profile from "../Pages/profile/index";
import Student from "../Pages/student/index";
import UserProfileLite from "../views/UserProfileLite";
import AddNewPost from "../views/AddNewPost";
// import Errors from "../views/Errors";
import ComponentsOverview from "../views/ComponentsOverview";
import Tables from "../views/Tables";
import BlogPosts from "../views/BlogPosts";
import Questions from "../Pages/quiz/components/Questions";
import QuestionForm from "../Pages/quiz/components/QuestionForm";
import Dummy from "../Pages/dummy/index";
import Chat from "../Pages/chat/index";
import Payment from "../Pages/payment/index";
import StudentQuizSubmission from "../Pages/quiz-submission/components/StudentQuizSubmission";
import StudentQuickQuizSubmission from "../Pages/quick-quiz-submission/components/StudentQuickQuizSubmission";

// tqb components

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/institute-overview" />
  },
  {
    path: "/dummy",
    layout: DefaultLayout,
    component: Dummy
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/institute-overview",
    layout: DefaultLayout,
    component: InstituteOverview
  },
  {
    path: "/sessions",
    layout: DefaultLayout,
    component: Sessions,
    protected: true
  },
  {
    path: "/quiz",
    layout: DefaultLayout,
    component: Quiz
  },
  {
    path: "/quickQuiz",
    exact: true,
    layout: DefaultLayout,
    component: QuickQuiz
  },
  {
    path: "/quickQuiz/:id",
    exact: true,
    layout: DefaultLayout,
    component: QuickQuizPaper
  },
  {
    path: "/batch",
    layout: DefaultLayout,
    component: Batch,
    protected: true
  },
  {
    path: "/questions/:id",
    exact: true,
    layout: DefaultLayout,
    component: Questions
  },
  {
    path: "/addQuestion/:id",
    exact: true,
    layout: DefaultLayout,
    component: QuestionForm
  },
  {
    path: "/classes",
    layout: DefaultLayout,
    component: Class,
    protected: true
  },
  {
    path: "/subjects",
    layout: DefaultLayout,
    component: Subject,
    protected: true
  },
  {
    path: "/batch-subjects",
    layout: DefaultLayout,
    component: BatchSubject,
    protected: true
  },
  {
    path: "/students",
    layout: DefaultLayout,
    component: Student,
    protected: true
  },
  {
    path: "/institute",
    layout: DefaultLayout,
    component: Institute,
    protected: true
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/quiz-submission",
    layout: DefaultLayout,
    component: QuizSubmission
  },
  {
    path: "/student-quiz-submission/:id",
    layout: DefaultLayout,
    component: StudentQuizSubmission
  },
  {
    path: "/quick-quiz-submission",
    layout: DefaultLayout,
    component: QuickQuizSubmission
  },
  {
    path: "/student-quickquiz-submission/:id",
    layout: DefaultLayout,
    component: StudentQuickQuizSubmission
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/chat",
    layout: DefaultLayout,
    component: Chat
  },
  {
    path: "/payment",
    layout: DefaultLayout,
    component: Payment
  }
  // {
  //   path: "/errors",
  //   layout: DefaultLayout,
  //   component: Errors
  // }
  // tqb components
];
