import { STUDENT_LOADED, STUDENT_LOADING, SET_STUDENT } from "../actions/types";

const initialState = {
  students: [],
  loading: false,
  mobileError: null,
  successCreate: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case STUDENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case STUDENT_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_STUDENT:
      return {
        ...state,
        students: action.payload,
        loading: false,
        mobileError: action.mobile,
        successCreate: action.successCreate
      };
    default:
      return state;
  }
}
