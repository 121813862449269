import { QUICK_QUIZ_SUBMISSIONFILES_LOADED, QUICK_QUIZ_SUBMISSIONFILES_LOADING, SET_QUICK_QUIZ_SUBMISSIONFILES } from "../actions/types";

const initialState = {
  submissionFiles: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case QUICK_QUIZ_SUBMISSIONFILES_LOADING:
      return {
        ...state,
        loading: true
      };
    case QUICK_QUIZ_SUBMISSIONFILES_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_QUICK_QUIZ_SUBMISSIONFILES:
      return {
        ...state,
        submissionFiles: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
