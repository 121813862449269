import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormSelect
} from "shards-react";

import { withRouter } from "react-router-dom";

const ClassForm = props => {
  const style = {
    backgroundColor: "#e1e5ea",
    margin: "0 4px 4px 0",
    padding: "3px 5px",
    borderRadius: "1%",
    display: "inline-block",
    color: "#000"
  };

  const style1 = {
    padding: "2px",
    cursor: "pointer",
    borderColor: "#e1e5ea"
  };

  return (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={e => props.submitHandler(e)}>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="className">Class</label>
                  <FormInput
                    id="className"
                    type="text"
                    placeholder="Class Name"
                    value={props.classData}
                    onChange={props.onClassNameChange}
                  />
                </Col>
                <Col md="12" className="form-group">
                  <label htmlFor="batchNames" style={{ width: "100%" }}>
                    Batches
                  </label>
                  {props.selectedBatches.map(batch => (
                    <p style={style} key={batch.id}>
                      {batch.batch_name}
                      <span
                        style={style1}
                        key={batch.id}
                        onClick={() => props.removeBatches(batch.id)}
                      >
                        <i className="fas fa-times-circle"></i>
                      </span>
                    </p>
                  ))}

                  <FormSelect
                    id="batchNames"
                    onChange={e => {
                      props.addBatches(e.target.value);
                    }}
                  >
                    <option value="">Select Batches...</option>
                    {props.batchArray.map(batch => (
                      <option key={batch.id} value={batch.id}>
                        {batch.batch_name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              <Button type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default withRouter(ClassForm);
