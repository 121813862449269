import { API } from "../../backend";
import axios from "axios";
import { QUIZ_SUBMISSIONS_LOADED, QUIZ_SUBMISSIONS_LOADING, SET_QUIZ_SUBMISSIONS } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getQuizSubmissions = (sessionId,instituteId,quizId) => async dispatch => {
  dispatch(quizSubmissionsLoading());
  axios
    .get(`${API}/answer-quiz?id=&session=${sessionId}&institute=${instituteId}&quiz_name=${quizId}&question=&user=`)
    .then(res => {
      const submissions = res.data.results;
      dispatch(setQuizSubmissions(submissions));
      dispatch(quizSubmissionsLoaded());
    })
    .catch(err => {
      dispatch(quizSubmissionsLoaded());
    });
};


export const getQuizSubmissionsByStudent = (sessionId,instituteId,quizId,userId) => async dispatch => {
  dispatch(quizSubmissionsLoading());
  axios
    .get(`${API}/answer-quiz?id=&session=${sessionId}&institute=${instituteId}&quiz_name=${quizId}&question=&user=${userId}`)
    .then(res => {
      const submissions = res.data.results;
      dispatch(setQuizSubmissions(submissions));
      dispatch(quizSubmissionsLoaded());
    })
    .catch(err => {
      dispatch(quizSubmissionsLoaded());
    });
};

export const setQuizSubmissions = submissions => {
  return {
    type: SET_QUIZ_SUBMISSIONS,
    payload: submissions
  };
};

export const quizSubmissionsLoading = () => {
  return {
    type: QUIZ_SUBMISSIONS_LOADING
  };
};

export const quizSubmissionsLoaded = () => {
  return {
    type: QUIZ_SUBMISSIONS_LOADED
  };
};



export const updateMarks = (questionId,userId,marks) => async dispatch => {
  const res = await  axios.get(`${API}/answer-quiz?id=&session=&institute=&quiz_name=&question=${questionId}&user=${userId}`)
  const answerId = res.data.results[0].id;
  await  axios.put(`${API}/answer-quiz/${answerId}`,marks)
};