import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Card,
  Form,
  FormInput,
  Button,
  FormSelect,
  FormCheckbox
} from "shards-react";

import 'antd/dist/antd.css';

import ReactQuill from "react-quill";

import CustomFileUpload from "../../../components/common/CustomFileUpload";
import folderImage from "../../../images/signin/folder.png";

const QuestionForm = (props) => {
  const dispatch = useDispatch();

  const question = props.data;
  const quiz = useSelector(state => state.quiz);
  const curQuiz = quiz.curQuiz;
  const [textQuestion, setTextQuestion] = useState(question && question.question);
  const [textAnswer, setTextAnswer] = useState(question && question.answer);
  const [textSolutionText, setTextSolutionText] = useState(question && question.solution_text);
  const [formType, setFormType] = useState(question && question.question_type);
  const [correctA, setCorrectA] = useState(question && question.answer_a);
  const [correctB, setCorrectB] = useState(question && question.answer_b);
  const [correctC, setCorrectC] = useState(question && question.answer_c);
  const [correctD, setCorrectD] = useState(question && question.answer_d);
  const [optionA, setOptionA] = useState(question && question.option_a);
  const [optionB, setOptionB] = useState(question && question.option_b);
  const [optionC, setOptionC] = useState(question && question.option_c);
  const [optionD, setOptionD] = useState(question && question.option_d);
  const [maxMarks, setMaxMarks] = useState(question && question.max_marks);
  
  useEffect(() => {
    if(!question){
      if(curQuiz && (curQuiz.question_type==="mcq" || curQuiz.question_type==="all")){
        setFormType("mcq");
      }
      else{
        setFormType("text");
      }  
    }
  },[])

  const setFormHandler = (e) => {
    e.preventDefault();
    setTextQuestion("");
    setTextAnswer("");
    setTextSolutionText("");
    setCorrectA(false);
    setCorrectB(false);
    setCorrectC(false);
    setCorrectC(false);
    setOptionA("");
    setOptionB("");
    setOptionC("");
    setOptionD("");
    setMaxMarks("");
    setFormType(e.target.value);
  }

  const setCorrectAnswer = (ans) => {
    if(formType==="mcq"){
      setCorrectA(false);
      setCorrectB(false);
      setCorrectC(false);
      setCorrectD(false);
      if(ans==="A"){
        setCorrectA(true);
      }
      if(ans==="B"){
        setCorrectB(true);
      }
      if(ans==="C"){
        setCorrectC(true);
      }
      if(ans==="D"){
        setCorrectD(true);
      }
    }
    else{
      if(ans==="A"){
        setCorrectA(!correctA);
      }
      if(ans==="B"){
        setCorrectB(!correctB);
      }
      if(ans==="C"){
        setCorrectC(!correctC);
      }
      if(ans==="D"){
        setCorrectD(!correctD);
      }
    }
  }

  const item = props.data;

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [showFileName, setShowFileName] = useState(false);

  useEffect(() => {
    if(item){
      setImage(item.solution_image);
      console.log(image);
      if (item.solution_image) {
        setImageName(item.solution_image.substring(40));
      }  
    }
  }, [dispatch, item]);

  return (
    <>
      {/* Question Form */}
      {formType==="text" &&
          <Card>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
                <Row>
                  <Col md="12">
                    <Form onSubmit={(e) => props.submitHandler(e)}>
                      <Row form>
                        <Col md="4" className="form-group">
                          {!question && curQuiz && curQuiz.question_type==="all" && 
                            <>
                              <label>Question Type</label>
                              <FormSelect onChange={(e) => setFormHandler(e)} id="question_type">
                                <option selected={formType==="text"} value="text">Text</option>
                                <option selected={formType==="mcq"} value="mcq">MCQ (Single Correct)</option>
                                <option selected={formType==="multiplecorrect"} value="multiplecorrect">MCQ (Multi Correct)</option>
                              </FormSelect>
                            </>
                          }
                        </Col>
                        <Col md="12" className="form-group">
                          <FormInput
                            className="d-none"
                            id="question"
                            type="text"
                            value={textQuestion ? textQuestion : ""}
                          />
                          <label>Question Statement</label>
                          <ReactQuill
                            required
                            value={textQuestion ? textQuestion : ""}
                            className="mb-1"
                            onChange={(e) => setTextQuestion(e)}
                            placeholder="Write question statement here"
                            defaultValue = {question && question.question}
                          />
                        </Col>
                        <Col md="12" className="form-group">
                          <FormInput
                            className="d-none"
                            id="answer"
                            type="text"
                            value={textAnswer ? textAnswer : ""}
                          />
                          <label>Answer Statement</label>
                          <ReactQuill
                            required
                            id="answer"
                            value={textAnswer ? textAnswer : ""}
                            className="mb-1"
                            onChange={(e) => setTextAnswer(e)}
                            placeholder="Write Answer here"
                            defaultValue = {question && question.answer}
                          />
                        </Col>
                        <Col md="12" className="form-group">
                          <FormInput
                            className="d-none"
                            id="solution_text"
                            type="text"
                            value={textSolutionText ? textSolutionText : ""}
                          />
                          <label>Explanation</label>
                          <ReactQuill
                            required
                            id="solution_text"
                            value={textSolutionText ? textSolutionText : ""}
                            className="mb-1"
                            onChange={(e) => setTextSolutionText(e)}
                            placeholder="Write Solution text here"
                            defaultValue = {question && question.solution_text}
                          />
                        </Col>
                        {/* Logo */}
                        <Col md="6" className="form-group">
                          <label htmlFor="logo">Quiz logo</label>
                          <CustomFileUpload
                            filename={imageName}
                            onFileNameChange={e => {
                              setImage(
                                URL.createObjectURL(e.target.files[0])
                              );
                              setShowFileName(false);
                              setImageName(e.target.files[0].name);
                            }}
                          />
                          {image !== null && (
                            <a href={image} target="_blank" rel="noopener noreferrer">
                              <img
                                onError={e => {
                                  e.target.src = folderImage;
                                  e.target.width = "30";
                                  setShowFileName(true);
                                }}
                                className="square ml-3"
                                src={image}
                                alt={imageName}
                                width="60"
                              />
                              {showFileName === true && (
                                <p
                                  style={{
                                    color: "#000",
                                    fontSize: "15px",
                                    display: "inline-block"
                                  }}
                                >
                                  {imageName}
                                </p>
                              )}
                            </a>
                          )}
                        </Col>
                        <Col md="6" className="form-group">
                          <label>Max Score</label>
                          <FormInput
                            required
                            id="max_marks"
                            type="number"
                            placeholder="Enter Max Marks"
                            defaultValue={question && question.max_marks}
                          />
                        </Col>
                      </Row>
                      <Button type="submit">Submit</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
      }
      {(formType==="mcq" || formType==="multiplecorrect") &&
          <Card>
            <ListGroup flush>
              <ListGroupItem className="p-3" style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
                <Row>
                  <Col md="12">
                    <Form onSubmit={(e) => props.submitHandler(e)}>
                      <Row form>
                        <Col md="4" className="form-group">
                          {!question && curQuiz && curQuiz.question_type==="all" && 
                            <>
                              <label>Question Type</label>
                              <FormSelect onChange={(e) => setFormHandler(e)} id="question_type">
                                <option selected={formType==="text"} value="text">Text</option>
                                <option selected={formType==="mcq"} value="mcq">MCQ (Single Correct)</option>
                                <option selected={formType==="multiplecorrect"} value="multiplecorrect">MCQ (Multi Correct)</option>
                              </FormSelect>
                            </>
                          }
                        </Col>
                        <Col md="12" className="form-group">
                          <FormInput
                            className="d-none"
                            id="question"
                            type="text"
                            value={textQuestion ? textQuestion : ""}
                          />
                          <label>Question Statement</label>
                          <ReactQuill
                            required
                            value={textQuestion ? textQuestion : ""}
                            className="mb-1"
                            onChange={(e) => setTextQuestion(e)}
                            placeholder="Write question statement here"
                            defaultValue = {question && question.question}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <FormInput
                            required
                            id="option_a"
                            type="text"
                            placeholder="Option A"
                            value={optionA ? optionA : ""}
                            onChange={(e) => {setOptionA(e.target.value)}}
                            defaultValue = {question && question.option_a}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <FormInput
                            required
                            id="option_b"
                            type="text"
                            placeholder="Option B"
                            value={optionB ? optionB : ""}
                            onChange={(e) => {setOptionB(e.target.value)}}
                            defaultValue = {question && question.option_b}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <FormInput
                            required
                            id="option_c"
                            type="text"
                            placeholder="Option C"
                            value={optionC ? optionC : ""}
                            onChange={(e) => {setOptionC(e.target.value)}}
                            defaultValue = {question && question.option_c}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <FormInput
                            required
                            id="option_d"
                            type="text"
                            placeholder="Option D"
                            value={optionD ? optionD : ""}
                            onChange={(e) => {setOptionD(e.target.value)}}
                            defaultValue = {question && question.option_d}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <label>{formType==="multiplecorrect" && "Multiple "}Correct Answer</label>
                          <FormCheckbox
                            id="answer_a"
                            value={correctA}
                            checked={correctA}
                            onClick={() => setCorrectAnswer('A')}
                          >
                            Option A
                          </FormCheckbox>
                          <FormCheckbox
                            id="answer_b"
                            value={correctB}
                            checked={correctB}
                            onClick={() => setCorrectAnswer('B')}
                          >
                            Option B
                          </FormCheckbox>
                          <FormCheckbox
                            id="answer_c"
                            value={correctC}
                            checked={correctC}
                            onClick={() => setCorrectAnswer('C')}
                          >
                            Option C
                          </FormCheckbox>
                          <FormCheckbox
                            id="answer_d"
                            value={correctD}
                            checked={correctD}
                            onClick={() => setCorrectAnswer('D')}
                          >
                            Option D
                          </FormCheckbox>
                        </Col>
                        <Col md="6" className="form-group">
                          <label>Max Score</label>
                          <FormInput
                            required
                            id="max_marks"
                            type="number"
                            placeholder="Enter Max Marks"
                            value={maxMarks ? maxMarks : 0}
                            onChange={(e) => setMaxMarks(e.target.value)}
                            defaultValue={question && question.max_marks}
                          />
                        </Col>
                        <Col md="12" className="form-group">
                          <FormInput
                            className="d-none"
                            id="solution_text"
                            type="text"
                            value={textSolutionText ? textSolutionText : ""}
                          />
                          <label>Explanation</label>
                          <ReactQuill
                            required
                            id="solution_text"
                            value={textSolutionText ? textSolutionText : ""}
                            className="mb-1"
                            onChange={(e) => setTextSolutionText(e)}
                            placeholder="Write Solution text here"
                            defaultValue = {question && question.solution_text}
                          />
                        </Col>
                        {/* Logo */}
                        <Col md="6" className="form-group">
                          <label htmlFor="logo">Quiz logo</label>
                          <CustomFileUpload
                            required
                            filename={imageName}
                            onFileNameChange={e => {
                              setImage(
                                URL.createObjectURL(e.target.files[0])
                              );
                              setShowFileName(false);
                              setImageName(e.target.files[0].name);
                            }}
                          />
                          {image !== null && (
                            <a href={image} target="_blank" rel="noopener noreferrer">
                              <img
                                onError={e => {
                                  e.target.src = folderImage;
                                  e.target.width = "30";
                                  setShowFileName(true);
                                }}
                                className="square ml-3"
                                src={image}
                                alt={imageName}
                                width="60"
                              />
                              {showFileName === true && (
                                <p
                                  style={{
                                    color: "#000",
                                    fontSize: "15px",
                                    display: "inline-block"
                                  }}
                                >
                                  {imageName}
                                </p>
                              )}
                            </a>
                          )}
                        </Col>
                      </Row>
                      <Button type="submit">Submit</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
      }
    </>
  );
};

export default withRouter(QuestionForm);