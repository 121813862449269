import { API } from "../../backend";
import React, { useEffect,useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Form,
  FormInput,
  Col,
  Button
} from "shards-react";
import 'antd/dist/antd.css';
import { Popover } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import PageTitle from "../../components/common/PageTitle";
import PopUp from "../../components/common/PopUp";

import "../../assets/chat.css";

import { ws } from "./components/Socket";
import axios from "axios";
import { compose } from "redux";

const Chat = () => {

  const user = useSelector(state => state.auth.user);

  const [list_of_rooms, set_list_of_rooms] = useState([]);

  useEffect(() => {
    axios.get(`${API}/get-user-rooms`)
      .then(res => {
        set_list_of_rooms(res.data.results);
      })
      .catch(err => {
        console.log(err);
      })
  },[])

  const [loading, setLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [message, setMessage] = useState("");
  const [lastFetched, setLastFetched] = useState([]);
  const [start, setStart] = useState(0);
  const [pendingMessages, setPendingMessages] = useState(new Map());

  ws.onopen = () => {
    console.log("Web Socket Connected!");
  }
  if(ws){
    ws.onmessage = (e) =>  { 
      var received_msg = JSON.parse(e.data);
      // console.log("received : ", received_msg);
      if(received_msg.display_progress_bar!==null){
        setLoading(received_msg.display_progress_bar);
      }
      if(received_msg.msg_type===0 && user.username!==received_msg.username){
        const receivingRoom = received_msg.room_id;
        // console.log("here : ", receivingRoom , room);
        if(room!==receivingRoom){
          let tmpMap = pendingMessages;
          if(pendingMessages.get(receivingRoom)){
            tmpMap.set(receivingRoom,  pendingMessages.get(receivingRoom) + 1);
          }
          else{
            tmpMap.set(receivingRoom, 1);
          }
          // console.log("pending : ", tmpMap);
          setPendingMessages(tmpMap);
          setStart(!start);
        }
        else{
          let tmp = messageList;
          tmp.push(received_msg)
          // console.log(tmp);
          setMessageList(tmp);
          setStart(!start);
        }
        var scrollDiv = document.querySelector(".chat-container");
        if(scrollDiv){
          scrollDiv.scrollTop = scrollDiv.scrollHeight - scrollDiv.clientHeight;
        }
      }
      if(received_msg.messages && received_msg.messages!=="None"){
        let tmp = messageList;
        setLastFetched(received_msg.messages);
        if(page===1){
          tmp = [];
        }
        tmp = received_msg.messages.reverse().concat(tmp);
        setStart(!start);
        setMessageList(tmp);
        setPage(received_msg.new_page_number);
      }
    };  
  }
  ws.onclose  = () => {
    alert("Web Socket Disconnected!");
  }
  ws.onerror  = () => {
    alert("Web Socket Error!");
  }

  const changeHandler = (e) => {
    setMessage(e.target.value);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if(message && room){
      sendMessageHandler();
    }
    else{
      console.log("room_id or message missing");
    }
  }

  const scrollHandler = () => {
    const chatLog = document.getElementsByClassName("chat-container")[0];
    if (chatLog.scrollTop===0) {
      // console.log("top");
      getMessageHandler(room, page);
    }
  }

  const sendMessageHandler = () => {
    // console.log("trying to send message { " + message + " } to room : " + room);
    ws.send(JSON.stringify({
      "command": "send",
      "message": message,
      "room_id": room
    }));
    setPage(1);
    setMessage("");
    getMessageHandler(room, 1);
  }

  const [room, setRoom] = useState(null);
  const [page, setPage] = useState(1);

  const changeRoomHandler = (room_id) => {
    // console.log("trying to join room : ", room_id);
    let tmpMap = pendingMessages;
    tmpMap.set(room_id, 0);
    setPendingMessages(tmpMap);
    setRoom(room_id);
  }

  useEffect(() => {
    if(room && page===1){
      var scrollDiv = document.querySelector(".chat-container");
      if(scrollDiv){
        scrollDiv.scrollTop = scrollDiv.scrollHeight - scrollDiv.clientHeight;
      }
    }
    if(room && page!==1){
      var scrollDivTmp = document.querySelector(".chat-content-tmp");
      const ht = scrollDivTmp.scrollHeight;
      var scrollDiv = document.querySelector(".chat-container");
      if(scrollDiv){
        scrollDiv.scrollTop = ht;
      }
    }
  },[messageList])

  useEffect(() => {
    if(room){
      joinRoomHandler(room);
      getMessageHandler(room, 1);
      setPage(1);
    }
  },[room])

  const joinRoomHandler = (room_id) => {
    ws.send(JSON.stringify({
      "command": "join",
      "room_id": room_id
    }))
  }

  const getMessageHandler = (room_id, page_no) => {
    if(page_no!==-1){
      // console.log("room id : ", room_id);
      // console.log("page no. : ", page_no);
      ws.send(JSON.stringify({
        "command": "get_room_chat_messages",
        "room_id": room_id,
        "page_number": page_no,
      }));
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Chat"
          subtitle="Chat"
          className="text-sm-left mb-3"
        />
      </Row>
      {/* To get the height to be removed from scroll bar while receiving new messages */}
      <Row className="chat-content-tmp m-0 p-0 hidden">
        {lastFetched.map((item, index) => {
          return (
            item.username==="user@gmail.com" ? 
              <Col xs="12" key={index}>
                <div className="user-msg float-right px-2 py-1 m-1">
                  {item.message}
                  <br/>
                  <span className="msgTime float-right">{(item.natural_timestamp.slice(-7))}</span>
                </div>
              </Col>
              :
              <Col xs="12" key={index}>
                <div className="msg px-2 float-left py-1 m-1">
                  {item.message}
                  <br/>
                  <span className="msgTime float-right">{(item.natural_timestamp.slice(-7))}</span>
                </div>
              </Col>
          );
        })}
      </Row>
      {/* Form */}
      <Row>
        <Col lg="8" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom" style={{display:"flex",justifyContent: "space-between"}}>
              <h6 
                className="m-0" 
                style={{display:"flex",justifyContent: "flex-right",flex:"1"}}
              >
                Send Message
              </h6>
            </CardHeader>
            <CardBody>
              <Row onScroll={() => scrollHandler()} className="chat-container">
                <Row className="mx-auto">{loading ? "Loading!" : ""}</Row>
                <Row className="chat-content m-0 p-0">
                  {messageList.map((item, index) => {
                    return (
                      item.username===user.username ? 
                        <Col xs="12" key={index}>
                          <div className="user-msg float-right px-2 py-1 m-1">
                            {item.message}
                            <br/>
                            <span className="msgTime float-right">{(item.natural_timestamp.slice(-7))}</span>
                          </div>
                        </Col>
                        :
                        <Col xs="12" key={index}>
                          <div className="msg px-2 float-left py-1 m-1">
                            <span className="msgName float-left">{(item.full_name)}</span>
                            <br/>
                            {item.message}
                            <br/>
                            <span className="msgTime float-right">{(item.natural_timestamp.slice(-7))}</span>
                          </div>
                        </Col>
                    );
                  })}
                </Row>
              </Row>
            </CardBody>
            <CardFooter>
              <Form onSubmit={(e) => submitHandler(e)}>
                <Row>
                  <Col xs="10" sm="11" className="mx-0">
                    <FormInput
                      autoComplete="off"
                      id="message"
                      type="text"
                      placeholder="Type here"
                      onChange={(e) => changeHandler(e)}
                      value={message}
                    />                    
                  </Col>
                  <Col xs="2" sm="1" className="mx-0 px-0">
                    <Button theme="primary" className="circle-btn my-auto" type="submit">
                      <ArrowRightOutlined className="icon"/>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="4" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom" style={{display:"flex",justifyContent: "space-between"}}>
              <h6 
                className="m-0" 
                style={{display:"flex",justifyContent: "flex-right",flex:"1"}}
              >
                Rooms
              </h6>
            </CardHeader>
            <CardBody>
              {list_of_rooms.map((item, idx) => {
                return (
                  <Col key={item.id}
                    onClick={() => changeRoomHandler(item.id)}
                    className="my-1"
                  >
                    {item.title}
                    <Badge className="float-right" pill theme="primary">
                      {item.id!==room ?
                        (pendingMessages.get(item.id)!==0 && pendingMessages.get(item.id)!==null) && pendingMessages.get(item.id)
                      : 
                        ""
                      }
                    </Badge>
                    <hr/>
                  </Col>
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Chat);