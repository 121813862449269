import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormCheckbox
} from "shards-react";

import 'antd/dist/antd.css';
import { Popover } from 'antd';

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import PopUp from "../../components/common/PopUp";

import SessionForm from "./components/SessionForm";

import { getSessions, createSession, editSession , deleteSession, changeCurrentSession } from "../../redux/actions/sessionActions";

const Sessions = () => {

  const dispatch = useDispatch();
  const [addItem,setAddItem] = useState(true);
  const [item,setItem] = useState(null);

  const session = useSelector(state => state.session);
  const auth = useSelector(state => state.auth);
  
  const items = session.sessions;
  const user = auth.user;

  // Get

  useEffect(() => {
    dispatch(getSessions());
  },[dispatch]);

  //Date Format Function

  const dateFunction = (date) => {
    return (
      `${(new Date(date).getDate() < 10 ? '0' : '') + 
      new Date(date).getDate()}-${(new Date(date).getMonth() + 1 < 10 ? '0' : '') + 
      (new Date(date).getMonth() + 1)}-${new Date(date).getFullYear()}`
    )
  }

  //for pagination
  const [currentPage,setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem,indexOfLastItem);
  const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
  }   

  // Pop up
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = (id) => {
    setDeleteItemId(id);
    setDeletePopUpVisible(true);
  }  

  const showPopUp = (message) => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  }

  // Delete

  const deleteHandler = async() => {
    dispatch(deleteSession(deleteItemId));
    if(currentItems.length===1){
      paginate(Math.max(currentPage-1,1))
    }
    showPopUp("Item deleted Successfully!");
  }

  // Edit
  const editHandler = (data) => {
    setItem(data);
    setAddItem(false);
  }

  // Create and Edit

  const submitHandler = (e) => {
    e.preventDefault();
    if(addItem===true){
      const sessionName = (e.target.sessionName.value);
      const startDate = (e.target.startDate.value);
      const endDate = (e.target.endDate.value);
      const data = {
        "start_date": startDate,
        "end_date": endDate,
        "session": sessionName,
        "current_session": false,
        "status": false,
        "institute": user.institute.id
      }
      dispatch(createSession(data));
      if(currentItems.length===itemsPerPage){
        paginate(currentPage+1)
      }
      showPopUp("Session added Successfully!");
      e.target.reset();
    }
    else{
      const sessionName = (e.target.sessionName.value);
      const startDate = (e.target.startDate.value);
      const endDate = (e.target.endDate.value);
      const data = 
      {
        "start_date": startDate,
        "end_date": endDate,
        "session": sessionName,
        "current_session": false,
        "status": false,
        "institute": user.institute.id
      }
      dispatch(editSession(data,item.id));
      setAddItem(true)
      showPopUp("Session edited Successfully!");
    }
    setItem(null);
  }

  const addItemTrueHandler = () => {
    setAddItem(true);
    setItem(null);
  }

  // Set Current Session

  const setCurrentSessionHandler = (newSession) => {
    dispatch(changeCurrentSession(newSession,session.curSession));
    showPopUp("Session changed Successfully!");
  }

  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }} 
        theme="white" size="sm" className="mx-1"
      >
        Cancel
      </Button>
      <Button 
        onClick={()=>{
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        size="sm" className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage}/>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Sessions"
          subtitle="Sessions"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Session Form */}
      <Row>
        <Col lg="4" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom" style={{display:"flex",justifyContent: "space-between"}}>
              <h6 
                className="m-0" 
                style={{display:"flex",justifyContent: "flex-right",flex:"1"}}
              >
                {addItem ? "Add Session" : "Edit Session"}
              </h6>
              {!addItem &&
                <span 
                  type="primary" 
                  size="small" 
                  style={{display:"flex",justifyContent:"flex-end",flex:"1",cursor:"pointer",textAlign:"left",verticalAlign:"center"}}
                  onClick={() => addItemTrueHandler()}
                >
                  <Button type="primary" size="sm" className="mx-1" style={{paddingBottom:"5px"}}>
                    Cancel
                  </Button>
                </span>
              }
            </CardHeader>
            {item && <SessionForm submitHandler = {submitHandler} data={item}/>}
            {!item && <SessionForm submitHandler = {submitHandler}/>}
          </Card>
        </Col>
        <Col lg="8" className="mb-4">
        <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">SESSION LIST</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Session Name
                    </th>
                    <th scope="col" className="border-0">
                      Start Date
                    </th>
                    <th scope="col" className="border-0">
                      End Date
                    </th>
                    <th scope="col" className="border-0">
                      Current Session
                    </th>
                    <th scope="col" className="border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentItems) && currentItems.map((data, index) => 
                    <tr key={data.id}>
                      <td>{index+1+itemsPerPage*(currentPage-1)}.</td>
                      <td>{data.session}</td>
                      <td>{dateFunction(data.start_date)}</td>
                      <td>{dateFunction(data.end_date)}</td>
                      <td>
                        {data.id!==session.curSession && 
                          <FormCheckbox
                            toggle
                            checked = {data.current_session}
                            onChange = {() => setCurrentSessionHandler(data.id)}
                          >
                          </FormCheckbox>
                        }
                        {data.id===session.curSession && 
                          <FormCheckbox
                            toggle
                            checked = {data.current_session}
                          >
                          </FormCheckbox>
                        }
                      </td>
                      <td>
                        <Button type="primary" size="sm" className="mx-1" onClick={()=> editHandler(data)}>
                          Edit
                        </Button>
                        <Popover 
                          key={data.id}
                          content={content} 
                          title="Sure to delete?" 
                          trigger="click" 
                          visible={deletePopUpVisible && data.id===deleteItemId}
                          className="mx-1">
                          <Button disabled={data.id===session.curSession}
                            onClick={() => openDeletePopUp(data.id)} theme="danger" size="sm">
                            Delete
                          </Button>
                        </Popover>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CardBody>
          </Card>
          {items!==undefined && items.length>itemsPerPage && 
            <Pagination 
              paginate={paginate} 
              currentPage={currentPage} 
              totalItems={items.length} 
              itemsPerPage={itemsPerPage}
            />}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Sessions);