import { API } from "../../backend";
import axios from "axios";
import { INSTITUTE_LOADED, INSTITUTE_LOADING, SET_INSTITUTE } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getInstitute = id => async dispatch => {
  dispatch(instituteLoading());
  axios
    .get(`${API}/institute/${id}`)
    .then(res => {
      const institute = res.data;
      dispatch(setInstitute(institute));
      dispatch(instituteLoaded());
    })
    .catch(err => {
      dispatch(instituteLoaded());
    });
};

export const setInstitute = institute => {
  return {
    type: SET_INSTITUTE,
    payload: institute
  };
};

export const instituteLoading = () => {
  return {
    type: INSTITUTE_LOADING
  };
};

export const instituteLoaded = () => {
  return {
    type: INSTITUTE_LOADED
  };
};

export const editInstitute = (instituteData, id) => async dispatch => {
  try {
    dispatch(instituteLoading());
    await axios.put(`${API}/institute/${id}`, instituteData); //update institute
    dispatch(getInstitute(id));
    dispatch(instituteLoaded());
  } catch (error) {
    dispatch(instituteLoaded());
  }
};
