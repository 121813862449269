import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormSelect
} from "shards-react";

const AddStudentForm = props => {
  const classList = useSelector(state => state.class);
  const { classes } = classList;

  const [selectedClassBatch, setSelectedClassBatch] = useState([]);
 
  function getSelectedClassBatches(id) {
    if (classes.length !== 0) {
      const arr = classes.filter(classData => (Number)(classData.id) === (Number)(id));
      if (arr.length !== 0) setSelectedClassBatch([...arr[0].batch_name]);
    }
  }

  useEffect(() => {
    if (props.className !== "") {
      getSelectedClassBatches(props.className);
    }
  }, [props.className]);

  const warning = {
    color: "red",
    margin: "0",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "2px",
    marginLeft: "2px"
  };
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
      <h6 className="m-0">{props.data === null ? "Add" : "Edit"} Student</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form onSubmit={e => props.submitHandler(e)}>
                <Row form>
                    {/* Mobile Number */}
                    <Col md="6" className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    {props.data ===null?
                      <FormInput
                      id="mobileNumber"
                      type="number"
                      placeholder="Mobile Number"
                      required
                      defaultValue={props.data && props.data.user.mobile}
                    />:
                    <FormInput
                      id="mobileNumber"
                      type="number"
                      placeholder="Mobile Number"
                      required
                      disabled
                      defaultValue={props.data && props.data.user.mobile}
                    />
                    }
                    {props.mobileError && (
                      <p style={warning}>
                        <i class="fas fa-exclamation-triangle"></i> Mobile
                        number already exist
                      </p>
                    )}
                  </Col>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="email">Email</label>
                    <FormInput
                      type="email"
                      id="email"
                      placeholder="Email Address"
                      autoComplete="email"
                      defaultValue={props.data && props.data.user.email}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Password */}
                  <Col md="6" className="form-group">
                    <label htmlFor="password">{props.data === null ? "" : "Change "}Password</label>
                    {props.data===null?
                      <FormInput
                        type="password"
                        id="password"
                        placeholder="Password"
                        required 
                    /> 
                    :
                    <FormInput
                      type="password"
                      id="password"
                      placeholder="Password" 
                    />
                    }
                  </Col>
                  {/* Full Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <FormInput 
                      id="fullName" 
                      placeholder="Full Name"
                      defaultValue={props.data && props.data.user.full_name}
                      />
                  </Col>
                </Row>
                <Row form>
                  {/* Class Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="className">Class Name</label>
                    <FormSelect
                      id="className"
                      value={props.className}
                      onChange={e => {
                        props.onClassNameChange(e);
                        getSelectedClassBatches(e.target.value);
                      }}
                    >
                      <option value="">Select Class...</option>
                      {classes.map(classData => (
                        <option key={classData.id} value={classData.id}>
                          {classData.class_name}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                   {/* Batch Name */}
                   <Col md="6" className="form-group">
                  <label htmlFor="batchName">Batch</label>
                    <FormSelect
                      id="batchName"
                      value={props.batchName}
                      onChange={e => {
                        props.onBatchNameChange(e);
                      }}
                    >
                      <option value="">Select Batch...</option>
                      {selectedClassBatch.length !== 0 &&
                        selectedClassBatch.map(batch => (
                          <option key={batch.id} value={batch.id}>
                            {batch.batch_name}
                          </option>
                        ))}
                    </FormSelect>
                  </Col>

                </Row>
                <Button theme="accent">Submit</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default AddStudentForm;
