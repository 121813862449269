import React, { useEffect,useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "shards-react";


import 'antd/dist/antd.css';
import { Popover, Avatar } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';

import PageTitle from "../../components/common/PageTitle";
import PopUp from "../../components/common/PopUp";

import QuizForm from "./components/QuizForm";

import "../../assets/quickQuiz.css"

import { getQuizzes, deleteQuiz, createQuiz, editQuiz } from "../../redux/actions/quickQuizActions";

const QuickQuiz = (props) => {

  const color = ["red","DeepPink","blue","green","Navy","Purple","Gold","OliveDrab","Orange","RosyBrown"];

  const randomColor = (data) => {
    // random function to get an index
    const idx = (data.id*210 + 45 + data.id%13)%10;
    // const idx = 9;
    return color[idx];
  }
  const dispatch = useDispatch();
  const [addItem,setAddItem] = useState(true);
  const [item,setItem] = useState(null);

  const quiz = useSelector(state => state.quickQuiz);
  const auth = useSelector(state => state.auth);
  const session = useSelector(state => state.session);
  
  const items = quiz.quickQuizzes;
  const user = auth.user;

  const [view, setView] = useState("list");

  // Get

  useEffect(() => {
    if(user.institute && user.institute.id && session && session.curSession){
      dispatch(getQuizzes(session.curSession,user.institute.id));
    }
  },[dispatch]);

  // Pop up
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = (id) => {
    setDeleteItemId(id);
    setDeletePopUpVisible(true);
  }  

  const showPopUp = (message) => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  }
  

  // Delete

  const deleteHandler = async() => {
    dispatch(deleteQuiz(deleteItemId, session.curSession, user.institute.id));
    showPopUp("Item deleted Successfully!");
  }
  
  // Edit
  const editHandler = (data) => {
    setItem(data);
    setAddItem(false);
    setView("form");
  }

  const checkOMR = (json, count) => {
    var flg = true;
    if(Object.keys(json).length!==parseInt(count)){
      flg = false;
    }
    Object.keys(json).forEach((key, index) => {
      console.log("key : ", key);
      if(json[key]!=="A" && json[key]!=="B" && json[key]!=="C" && json[key]!=="D"){
        console.log(json[key]);
        flg = false;
      }
    });
    return flg;
  }

  // Create and Edit

  const submitHandler = (e) => {
    e.preventDefault();
    var data = new FormData();
    if(addItem===true){
      const json = JSON.parse(e.target.answers.value);
      if(checkOMR(json,e.target.question_count.value)){
        data.append("quiz_name", e.target.quizName.value);
        data.append("class_name", e.target.className.value);
        data.append("batch_name", e.target.batchName.value);
        data.append("subject", e.target.subjectNameSingle.value);
        data.append("status", true);
        data.append("session", session.curSession);
        data.append("institute", user.institute.id);
        data.append("quiz_image", e.target.file.files[0]);
        data.append("duration_minutes", e.target.duration.value);
        data.append("start_time", e.target.startTime ? e.target.startTime.value : "00:00:00");
        data.append("end_time", e.target.endTime ? e.target.endTime.value : "00:00:00");
        data.append("date", e.target.date.value);
        data.append("is_negative_marking", e.target.isNegativeMarking.value);
        data.append("is_partial_marking", e.target.isPartialMarking.value);
        data.append("no_of_question", e.target.question_count.value);
        data.append("total_marks", e.target.marks.value);
        data.append("omr_answer", JSON.stringify(json));
        dispatch(createQuiz(data, session.curSession, user.institute.id));
        e.target.reset();
        showPopUp("Quiz added Successfully!");
      }
      else{
        showPopUp("OMR incomplete!");
      }
    }
    else{
      const json = JSON.parse(e.target.answers.value);
      if(checkOMR(json,e.target.question_count.value)){
        data.append("quiz_name", e.target.quizName.value);
        if(e.target.file.files[0]){
          console.log(e.target.file);
          data.append("quiz_image", e.target.file.files[0]);
        }
        data.append("duration_minutes", e.target.duration.value);
        data.append("start_time", e.target.startTime ? e.target.startTime.value : "00:00:00");
        data.append("end_time", e.target.endTime ? e.target.endTime.value : "00:00:00");
        data.append("date", e.target.date.value);
        data.append("is_negative_marking", e.target.isNegativeMarking.value);
        data.append("is_partial_marking", e.target.isPartialMarking.value);
        data.append("no_of_question", e.target.question_count.value);
        data.append("total_marks", e.target.marks.value);
        data.append("omr_answer", JSON.stringify(json));
        const id = item.id;
        dispatch(editQuiz(data, id, session.curSession, user.institute.id));
        setView("list");
        showPopUp("Quiz edited Successfully!");  
      }
      else{
        showPopUp("OMR incomplete!");
      }
    }
  }

  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }} 
        theme="white" size="sm" className="mx-1"
      >
        Cancel
      </Button>
      <Button 
        onClick={()=>{
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        size="sm" className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
     <PopUp message={popMessage}/>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Quick Quiz"
          subtitle="Quick Quiz"
          className="text-sm-left mb-3"
        />
        {view==="form" ? 
          <Button type="primary" size="sm" onClick={() => {setView("list");setItem(null);setAddItem(true)}}  className="ml-auto mb-3">
            Back
          </Button>
          :
          <Button type="primary" size="sm" onClick={() => {setView("form");setItem(null);setAddItem(true)}}  className="ml-auto mb-3">
            New Quiz
          </Button>
        }
      </Row>
  

      <Row>
        {view==="form" ? 
          <>
            <Col lg="12" className="mb-4">
              <Card small>
                <CardHeader className="border-bottom" style={{display:"flex",justifyContent: "space-between"}}>
                  <h6 
                    className="m-0" 
                    style={{display:"flex",justifyContent: "flex-right",flex:"1"}}
                  >
                    {addItem ? "Add Quiz" : "Edit Quiz"}
                  </h6>
                </CardHeader>
                <QuizForm submitHandler = {submitHandler} data={item}/> 
              </Card>
            </Col>
          </>
           : 
           <Col lg="12" className="mb-4">
            <Row className="p-5">
              {items.map((data,index) => 
                <Col lg="4" key={index} md="6" className="mb-4">
                  <Card small className="mb-4">
                    <CardBody className="border-bottom d-flex py-auto">
                      <Avatar 
                        style={{backgroundColor:randomColor(data)}}
                        onClick={() => props.history.push(`/quickQuiz/${data.id}`)}
                        shape="circle" size={50}
                      >
                        {data.quiz_name[0]}
                      </Avatar>
                      <strong className="my-auto ml-2">
                        {data.quiz_name}
                      </strong>
                      <Button theme="primary" className="ml-auto circle-btn my-auto" onClick={()=> editHandler(data)}>
                        <EditOutlined className="icon"/>
                      </Button>
                      <Popover 
                        key={data.id}
                        content={content} 
                        title="Sure to delete?" 
                        trigger="click" 
                        visible={deletePopUpVisible && data.id===deleteItemId}
                        className="mx-1">
                        <Button theme="danger" className="circle-btn my-auto" onClick={() => openDeletePopUp(data.id)}>
                          <DeleteOutlined className="icon"/>
                        </Button>
                      </Popover>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        }
      </Row>
    </Container>
  );
};

export default withRouter(QuickQuiz);