import React from "react";
import {
  Card,
  CardBody,
} from "shards-react";

import "../../assets/popUp.css";
 

const style1 = {
  height : "50px",
  width: "400px",
  position: "fixed",
  left: "40vw",
  zIndex: "10000", 
  backgroundColor: "black",
  color: "white",
  opacity: "0.7"
};

const PopUp = (props) => (
  <Card id="popUp" className="init1" small style={style1}>
    <CardBody className="p-0 text-center d-flex align-items-center justify-content-center">
      <p>{props.message}</p>
    </CardBody>

  </Card>
);

export default PopUp;
