import React, { useEffect } from "react";
import { withRouter, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import routes from "./routes/routes";
import Signin from "./Pages/Signin";
import Errors from "./views/Errors";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser } from "./redux/actions/authActions";
import { Store } from "./redux/Store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import { setUser } from "../src/redux/actions/authActions";
import { getSessions } from "../src/redux/actions/sessionActions";
import ForgotPassword from "./Pages/Signin/components/ForgotPassword";

import firebase from "./firebase";
import { notification } from "antd";
import { toast } from "react-toastify";

// Check for token and user data to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info
  const decoded = JSON.parse(localStorage.userData);
  // Set user and isAuthenticated
  Store.dispatch(setCurrentUser(decoded));
}

const App = props => {
  
  useEffect(() => {
    if(localStorage.jwtToken){
      props.setUser();
      props.getSessions();
    }
  },[]);

  //Push notification
  useEffect(() => {
    const messaging = firebase.messaging();
    messaging.requestPermission()
      .then(() => {
        console.log("success");
        return messaging.getToken()
      })
      .then(token => {
        console.log(token);
      })
      .catch(err => {
        console.log(err);
      })
    messaging.onMessage(payload => {
      console.log("payload: ", payload);
      toast(payload.data.message_body, {
        type:"success",
        onClick(){
          window.location.href = payload.data.frontend_url;
        }
      });
    })  
  })

  return (
    <Switch>
      <Route path="/signin" exact component={Signin} />
      <Route path="/forgot-password" exact component={ForgotPassword} />

      {props.auth.isAuthenticated ? (
        <>
          {routes.map((route, index) => {
              let Component = () => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              };
              if((!route.protected) || (route.protected && props.auth.user.roles===1)){
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={Component}
                  />
                );
              }
              return null
          })}
        </>
      ) : (
        <Redirect to="/signin" />
      )}
      <Route component={Errors} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { setUser, getSessions })(App));
