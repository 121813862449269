import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";

import { withRouter } from "react-router-dom";

const BatchForm = props => {
  return (
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={e => props.submitHandler(e)}>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="batchName">Batch</label>
                  <FormInput
                    id="batchName"
                    type="text"
                    placeholder="Batch Name"
                    value={props.batchData}
                    onChange={props.onBatchNameChange}
                  />
                </Col>
              </Row>
              <Button type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default withRouter(BatchForm);
