import { BATCH_LOADED, BATCH_LOADING, SET_BATCH} from "../actions/types";

const initialState = {
  batches: [],
  loading: false,
};

export default function(state = initialState, action){
  switch (action.type) {
    case BATCH_LOADING:
      return {
        ...state,
        loading: true
      };
    case BATCH_LOADED:
      return {
        ...state,
        loading: false,
      };
    case SET_BATCH:
      return {
        ...state,
        batches: action.payload,
        loading:false
      };
    default:
      return state;
  }
}
