export default function() {
  return [
    // {
    //   title: "Dashboard",
    //   to: "/blog-overview",
    //   htmlBefore: '<i class="material-icons">dashboard</i>',
    //   htmlAfter: ""
    // },
    {
      title: "Dashboard",
      to: "/institute-overview",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Institute",
      to: "/institute",
      htmlBefore: '<i class="material-icons">school</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Sessions",
      to: "/sessions",
      htmlBefore: '<i class="material-icons">perm_contact_calendar</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Classes",
      to: "/classes",
      htmlBefore: '<i class="material-icons">class</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Batches",
      to: "/batch",
      htmlBefore: '<i class="material-icons">group</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Subjects",
      to: "/subjects",
      htmlBefore: '<i class="material-icons">subject</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Batch Subjects",
      to: "/batch-subjects",
      htmlBefore: '<i class="material-icons">subject</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Quiz",
      to: "/quiz",
      htmlBefore: '<i class="material-icons">assignment</i>',
      htmlAfter: ""
    },
    {
      title: "Quick Quiz",
      to: "/quickQuiz",
      htmlBefore: '<i class="material-icons">assignment</i>',
      htmlAfter: ""
    },
    {
      title: "Quiz Submissions",
      to: "/quiz-submission",
      htmlBefore: '<i class="material-icons">assignment</i>',
      htmlAfter: ""
    },
    {
      title: "Quick Quiz Submissions",
      to: "/quick-quiz-submission",
      htmlBefore: '<i class="material-icons">assignment</i>',
      htmlAfter: ""
    },
    {
      title: "Students",
      to: "/students",
      htmlBefore: '<i class="material-icons">group</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Institute",
      to: "/institute",
      htmlBefore: '<i class="material-icons">school</i>',
      htmlAfter: "",
      protected: true
    },
    {
      title: "Chat",
      to: "/chat",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
    },
    {
      title: "Payment",
      to: "/payment",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
    },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts"
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post"
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview"
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables"
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite"
    // }
  ];
}
