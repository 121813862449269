import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";

import { withRouter } from "react-router-dom";

const SessionForm = (props) => {

  return(
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={(e) => props.submitHandler(e)}>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="sessionName">Session</label>
                  <FormInput
                    required
                    id="sessionName"
                    type="text"
                    placeholder="Session Name"
                    defaultValue={props.data && props.data.session}
                  />
                </Col>
                <Col md="12" className="form-group">
                  <label htmlFor="startDate">Start Date</label>
                  <FormInput
                    required
                    id="startDate"
                    type="date"
                    placeholder="Start Date"
                    defaultValue={props.data && props.data.start_date}
                  />
                </Col>
                <Col md="12" className="form-group">
                  <label htmlFor="endDate">End Date</label>
                  <FormInput
                    required
                    id="endDate"
                    type="date"
                    placeholder="End Date"
                    defaultValue={props.data && props.data.end_date}
                  />
                </Col>
              </Row>
              <Button type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  )

};

export default withRouter(SessionForm);