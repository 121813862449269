import { API } from "../../backend";
import axios from "axios";
import { SESSION_LOADED, SESSION_LOADING, SET_SESSION, SET_CURRENT_SESSION } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getSessions = () => dispatch => {
  axios
    .get(`${API}/session`)
    .then(res => {
      const sessions = res.data.results;
      const curSession = sessions.filter(session => {
        return (session.current_session);
      })
      dispatch(setCurrentSession(curSession[0].id));
      dispatch(setSessions(sessions));
      dispatch(sessionLoaded());
    })
    .catch(err => {
      dispatch(sessionLoaded());
    });
};

export const changeCurrentSession = (newSession, oldSession) => dispatch => {
  axios
    .put(`${API}/session/${newSession}`, {current_session: true})
    .then(() => {
      axios
      .put(`${API}/session/${oldSession}`, {current_session: false})
      .then(res => {
        dispatch(getSessions());
        dispatch(sessionLoaded());
      })
      .catch(err => {
        dispatch(sessionLoaded());
      });
    })
    .catch(err => {
      dispatch(sessionLoaded());
    });

}

export const createSession = (sessionData) => dispatch => {
  axios
    .post(`${API}/session`, sessionData)
    .then(res => {
      dispatch(getSessions());
      dispatch(sessionLoaded());
    })
    .catch(err => {
      dispatch(sessionLoaded());
    });
};

export const editSession = (sessionData, id) => dispatch => {
  axios
    .put(`${API}/session/${id}`, sessionData)
    .then(res => {
      dispatch(getSessions());
      dispatch(sessionLoaded());
    })
    .catch(err => {
      dispatch(sessionLoaded());
    });
};

export const deleteSession = (id) => dispatch => {
  axios
    .delete(`${API}/session/${id}`)
    .then(res => {
      dispatch(getSessions());
      dispatch(sessionLoaded());
    })
    .catch(err => {
      dispatch(sessionLoaded());
    });
};

export const setCurrentSession = id => {
  return {
    type: SET_CURRENT_SESSION,
    payload: id
  };
};

export const setSessions = sessions => {
  return {
    type: SET_SESSION,
    payload: sessions
  };
};

export const sessionLoading = () => {
  return {
    type: SESSION_LOADING
  };
};

export const sessionLoaded = () => {
  return {
    type: SESSION_LOADED
  };
};