import { PROFILE_LOADED, PROFILE_LOADING, SET_PROFILE } from "../actions/types";

const initialState = {
  profile: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true
      };
    case PROFILE_LOADED:
      return {
        ...state,
        loading: false
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
