import axios from "axios";
import { API } from "../../../backend";
import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, FormSelect } from "shards-react";

import Chart from "../../../utils/chart";
 
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class QuizGraph extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.state = {
      month: 1,
      session: this.props.session.curSession,
      sessionsList : this.props.session.sessions,
      quizData : new Array(30).fill(0),
      quickQuizData : new Array(30).fill(0),
    };
  }

  graphRender = async () => {
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 1 !== 0 ? "" : tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 15,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...this.props.chartOptions
    };

    const data1 =  this.props.chartData;
    data1.datasets[0].data =  this.state.quizData;
    data1.datasets[1].data =  this.state.quickQuizData;
    data1.labels = Array.from(new Array(this.state.quizData.length), (_, i) => (i+1));

    if(window.BlogUsersOverview !== undefined){
      window.BlogUsersOverview.destroy();
    }

    if(window.BlogUsersOverview !== undefined)
        window.BlogUsersOverview.destroy();
        
    window.BlogUsersOverview = new Chart(this.canvasRef.current, {
    type: "LineWithLine",
    data: data1,
    options: chartOptions
    });

    // They can still be triggered on hover.
    const buoMeta = window.BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[
      this.props.chartData.datasets[0].data.length - 1
    ]._model.radius = 0;  

    // Render the chart.
    window.BlogUsersOverview.render();
    console.log("new data : ", data1.datasets[0].data);
  }

  componentDidMount() {
    console.log("mount", this.state.session, this.state.month);
    const auth = this.props.auth;
    const user = auth.user;
    const session = this.state.session;
    const month = this.state.month;
    const data = {
      session_id : session,
      month : month
    }
    if(auth && user && user.institute){
      let apiURL = "";
      if(user.roles === 1){  //institute-wise
        apiURL = `${API}/institute-graph-data/${user.institute.id}`;
      }
      else{   //teacher-wise
        apiURL = `${API}/teacher-graph-data`;
      }
      axios.post(apiURL,data)
      .then(res => {
        const quizData = [];
        console.log(res.data.quiz);
        Object.entries(res.data.quiz).map(([key, value]) => {
          quizData.push(value);
        })
        const quickQuizData = [];
        Object.entries(res.data.quick_quiz).map(([key, value]) => {
          quickQuizData.push(value);
        })
        this.setState({
          quizData : quizData,
          quickQuizData : quickQuizData
        });
        this.graphRender();
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if((prevState.quizData===this.state.quizData) || (prevState.quickQuizData===this.state.quickQuizData)){
      console.log("update", this.state.session, this.state.month);
      const auth = this.props.auth;
      const user = auth.user;
      const session = this.state.session;
      const month = this.state.month;
      const data = {
        session_id : session,
        month : month
      }
      if(auth && user && user.institute){
        let apiURL = "";
        if(user.roles === 1){  //institute-wise
          apiURL = `${API}/institute-graph-data/${user.institute.id}`;
        }
        else{   // teacher-wise
          apiURL = `${API}/teacher-graph-data`;
        }
        axios.post(apiURL,data)
        .then(res => {
          const quizData = [];
          console.log("res : ", res.data.quiz);
          Object.entries(res.data.quiz).map(([key, value]) => {
            quizData.push(value);
          })
          const quickQuizData = [];
          Object.entries(res.data.quick_quiz).map(([key, value]) => {
            quickQuizData.push(value);
          })
          this.setState({
            quizData : quizData,
            quickQuizData : quickQuizData
          });
          this.graphRender();
        })
      }  
    }
  }

  monthChangeHandler = (e) => {
    e.preventDefault();
    const newMonth = e.target.value;
    this.setState({
      month: newMonth
    })
  }

  sessionChangeHandler = (e) => {
    e.preventDefault();
    const newSession = e.target.value;
    this.setState({
      session: newSession
    })
  }

  render() {
    const { title } = this.props;

    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <FormSelect onChange={(e) => this.monthChangeHandler(e)} id="month">
                <option selected={this.state.month===1} value={1}>January</option>
                <option selected={this.state.month===2} value={2}>February</option>
                <option selected={this.state.month===3} value={3}>March</option>
                <option selected={this.state.month===4} value={4}>April</option>
                <option selected={this.state.month===5} value={5}>May</option>
                <option selected={this.state.month===6} value={6}>June</option>
                <option selected={this.state.month===7} value={7}>July</option>
                <option selected={this.state.month===8} value={8}>August</option>
                <option selected={this.state.month===9} value={9}>September</option>
                <option selected={this.state.month===10} value={10}>October</option>
                <option selected={this.state.month===11} value={11}>November</option>
                <option selected={this.state.month===12} value={12}>December</option>
              </FormSelect>
            </Col>
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <FormSelect onChange={(e) => this.sessionChangeHandler(e)} id="session">
                {this.state.sessionsList.map((session, index) => {
                  return(
                    <option key={index} selected={this.state.session===session.id} value={session.id}>{session.session}</option>
                  );
                })}
              </FormSelect>
            </Col>
          </Row>
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
    );
  }
}

QuizGraph.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

QuizGraph.defaultProps = {
  title: "Quiz Overview",
  chartData: {
    labels: Array.from(new Array(31), (_, i) => (i === 0 ? null : i)),
    datasets: [
      {
        label: "Quiz",
        fill: "start",
        data: [],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Quick Quiz",
        fill: "start",
        data: [],
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderDash: [3, 3],
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
        pointBorderColor: "rgba(255,65,105,1)"
      }
    ]
  }
};

const mapStateToProps = state => ({
  session: state.session,
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(QuizGraph));