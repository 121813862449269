import { CLASS_LOADED, CLASS_LOADING, SET_CLASS } from "../actions/types";

const initialState = {
  classes: [],
  loading: false,
};

export default function(state = initialState, action){
  switch (action.type) {
    case CLASS_LOADING:
      return {
        ...state,
        loading: true
      };
    case CLASS_LOADED:
      return {
        ...state,
        loading: false,
      };
    case SET_CLASS:
      return {
        ...state,
        classes: action.payload,
        loading:false
      };
    default:
      return state;
  }
}
