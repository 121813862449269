import React, { useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";
import { withRouter } from "react-router-dom";
import { API } from "../../../backend";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./ChangePassword";
import axios from "axios";
toast.configure();

const ForgotPassword = () => {
  const [phone, setPhone] = useState("");
  const [otpLabel, setOtpLabel] = useState(false);
  const [otp, setOtp] = useState(false);
  const [isOtpCorrect, setIsOtpCorrect] = useState(null);

  const getOtp = () => {
    axios.post(`${API}/request-otp/`,{mobile:phone})
    setOtpLabel(true);
    toast("Otp has been sent to your mobile", { type: "success" });
  };

  const verifyOtp = async () => {
    //if otp is correct send to password chge page
    const data = {
      mobile:phone,
      otp:otp
    }
    const res = await axios.patch(`${API}/checkotp/`,data)
    if(res.data.otp_verified === true){
      setIsOtpCorrect(true)
    }
    else{
      setIsOtpCorrect(false);
    }    
  };

  const resendOtp = () => {
    axios.post(`${API}/request-otp/`,{mobile:phone})
    toast("Otp has been resent to your mobile", { type: "success" });
  };

  const warning = {
    color: "red",
    margin: "0",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "2px",
    marginLeft: "2px"
  };

  return (
    <div className="SignInPage">
      <div className="signInRow">
        <Col md={8} lg={8}>
          <div className="SignInImage"></div>
        </Col>
        {isOtpCorrect === true ? (
          <ChangePassword phone={phone} otp={otp}/>
        ) : (
          <Col md={4} lg={4} sm={12} xs={12}>
            <div className="signInForm">
              <div className="th-signin">
                <div className="container th-signin-container">
                  <h1 style={{ color: "#fff" }}>
                    <i className="material-icons">lock_outline</i>
                  </h1>
                  <h4 className="SignIn_header">Trouble Logging In?</h4>
                  <p className="SignIn_subheader1">
                    Enter your mobile number and we'll send you a otp to reset
                    your password.
                  </p>
                  <hr className="SignIn_line" />
                  <ListGroup flush>
                    <ListGroupItem className="p-3 th-signin-form-wraper">
                      <Form>
                        <Row>
                          <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            className="form-group"
                          >
                            <label htmlFor="femobileAddress">
                              Mobile Number
                            </label>
                            <FormInput
                              id="femobileAddress"
                              type="number"
                              placeholder="Mobile Number"
                              name="mobile"
                              value={phone}
                              onChange={e => setPhone(e.target.value)}
                              required
                            />
                          </Col>
                          {otpLabel === true && (
                            <Col
                              xs="12"
                              sm="12"
                              md="12"
                              lg="12"
                              xl="12"
                              className="form-group"
                            >
                              <label htmlFor="otp">OTP</label>
                              <FormInput
                                id="otp"
                                type="number"
                                placeholder="Enter OTP"
                                name="otp"
                                value={otp}
                                onChange={e => setOtp(e.target.value)}
                                required
                              />
                              {isOtpCorrect === false && (
                                <p style={warning}>
                                  <i className="fas fa-exclamation-triangle"></i>{" "}
                                  Incorrect OTP
                                </p>
                              )}
                            </Col>
                          )}
                          <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <Button
                              className="btn btn-block"
                              onClick={otpLabel === false ? getOtp : verifyOtp}
                            >
                              {otpLabel === false ? "Send OTP" : "Proceed"}
                            </Button>
                          </Col>
                          {otpLabel === true && (
                            <Col
                              xs="12"
                              sm="12"
                              md="12"
                              lg="12"
                              xl="12"
                              className="forgotPassword"
                            >
                              <span onClick={resendOtp}>Resend Otp</span>
                            </Col>
                          )}
                          <Col className="back-to-login">
                            <a href="/">Back To Login</a>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </div>
            </div>
          </Col>
        )}
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
