import { API } from "../../backend";
import axios from "axios";
import { SET_QUIZ, QUIZ_LOADED, QUIZ_LOADING, SET_CURRENT_QUIZ } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const createQuiz = (data, sessionID, instituteID) => dispatch => {
  axios
    .post(`${API}/quiz`, data)
    .then(res => {
      console.log("success");
      dispatch(getQuizzes(sessionID, instituteID));
      dispatch(quizLoaded());
    })
    .catch(err => {
      console.log("err : ", err);
      dispatch(quizLoaded());
    });
};

export const editQuiz = (data, id, sessionID, instituteID) => dispatch => {
  axios
    .put(`${API}/quiz/${id}`, data)
    .then(res => {
      console.log("success");
      dispatch(getQuizzes(sessionID, instituteID));
      dispatch(quizLoaded());
    })
    .catch(err => {
      console.log("err : ", err);
      dispatch(quizLoaded());
    });
};

export const getQuizzes = (sessionID, instituteID) => dispatch => {
  axios
    .get(`${API}/quiz?session=${sessionID}&institute=${instituteID}`)
    .then(res => {
      const quizzes = res.data.results;
      dispatch(setQuizzes(quizzes));
      dispatch(quizLoaded());
    })
    .catch(err => {
      dispatch(quizLoaded());
    });
};

export const deleteQuiz = (id, sessionID, instituteID) => dispatch => {
  axios
    .delete(`${API}/quiz/${id}`)
    .then(res => {
      dispatch(getQuizzes(sessionID, instituteID));
      dispatch(quizLoaded());
    })
    .catch(err => {
      dispatch(quizLoaded());
    });
};

export const setCurrentQuizHandler = (data) => dispatch => {
  dispatch(setCurrentQuiz(data));
}

export const setCurrentQuiz = data => {
  return {
    type: SET_CURRENT_QUIZ,
    payload: data
  };
};

export const setQuizzes = quizzes => {
  return {
    type: SET_QUIZ,
    payload: quizzes
  };
};

export const quizLoading = () => {
  return {
    type: QUIZ_LOADING
  };
};

export const quizLoaded = () => {
  return {
    type: QUIZ_LOADED
  };
};