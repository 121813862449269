import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  CardHeader,
  Card, 
  Container, 
  Row, 
  Col,
  ListGroup,
  ListGroupItem
} from "shards-react";

import 'antd/dist/antd.css';
import { Avatar } from 'antd';

import PageTitle from "../../components/common/PageTitle";

import { getQuizzes} from "../../redux/actions/quizActions";

const QuizSubmission = (props) => {

  const color = ["red","DeepPink","blue","green","Navy","Purple","Gold","OliveDrab","Orange","RosyBrown"];

  const randomColor = (data) => {
    // random function to get an index
    const idx = (data.id*210 + 45 + data.id%13)%10;
    // const idx = 9;
    return color[idx];
  }

  const dispatch = useDispatch();

  const quiz = useSelector(state => state.quiz);
  const auth = useSelector(state => state.auth);
  const session = useSelector(state => state.session);
  
  const items = quiz.quizzes;
  const user = auth.user;

  // Get


  useEffect(() => {
    if(user.institute){
      dispatch(getQuizzes(session.curSession,user.institute.id));
    }
  },[dispatch]);

  //Date Format Function

  const dateFunction = (date) => {
    return (
      `${(new Date(date).getDate() < 10 ? '0' : '') + 
      new Date(date).getDate()}-${(new Date(date).getMonth() + 1 < 10 ? '0' : '') + 
      (new Date(date).getMonth() + 1)}-${new Date(date).getFullYear()}`
    )
  }
  
  const setQuizHandler = (data) => {
    props.history.push(`/student-quiz-submission/${data.id}`);
  }

  

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Quiz Submission"
          subtitle="Quiz Submission"
          className="text-sm-left mb-3"
        />
      </Row>
  
      {/* Quiz Form */}
      <Row>
           <Col lg="12" className="mb-4">
            <Row className="p-5">
              {items.map((data,index) => 
                <Col key={index} lg="3" md="6" className="mb-4">
                  <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-center">
                      <div className="mb-3 mx-auto">
                        <Avatar 
                          style={{backgroundColor:randomColor(data)}}
                          onClick={() => setQuizHandler(data)} shape="circle" size={80}
                        >
                          {data.quiz_name[0].toUpperCase()}
                        </Avatar>
                      </div>
                      <h4 className="mb-1">{data.quiz_name}</h4>
                      <strong className="text-muted d-block mb-2">
                          Class Name : {data.class_name.class_name}
                        </strong>
                    </CardHeader>
                    <ListGroup flush>
                      <ListGroupItem className="px-4">
                        <strong className="text-muted d-block mb-2">
                          Date : {dateFunction(data.date)}
                        </strong>
                        <strong className="text-muted d-block mb-2">
                          Duration : {data.duration_minutes} minutes
                        </strong>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
      </Row>
    </Container>
  );
};

export default withRouter(QuizSubmission);
