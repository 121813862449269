import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import PageTitle from "../../components/common/PageTitle";
import SmallStats from "./components/SmallStats";
import QuizGraph from "./components/QuizGraph";
import QuizPiechart from "./components/QuizPiechart";
import axios from "axios";
import { API } from "../../backend";

const InstituteOverview = ({smallStats}) => {

  const [itemsCount,setItemsCount] = useState({});

  const auth = useSelector(state => state.auth);
  const user = auth.user;

  useEffect(()=>{
    if(auth &&user && user.institute){
      if(user.roles === 1){  //institutewise
        axios.get(`${API}/institute-data/${user.institute.id}`)
        .then(res => {
          setItemsCount(res.data);
        })
      }
      else{
        axios.get(`${API}/teacher-quiz-data`)
        .then(res => {
          setItemsCount(res.data);
        })
      }      
    }
  },[auth,user])

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Institute Overview"
          subtitle="Dashboard"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Institutewise Items Count*/}
      {user && user.roles===1 && (
        <Row>
          <Col className="col-lg mb-4">
            <SmallStats
              variation="1"
              label="Students"
              value={itemsCount.session_student_count}
              chartData= {smallStats[0].datasets}
              chartLabels={smallStats[0].chartLabels}
            />
          </Col>
          <Col className="col-lg mb-4">
            <SmallStats
              variation="1"
              label="Classes"
              value={itemsCount.class_count}
              chartData= {smallStats[1].datasets}
              chartLabels={smallStats[1].chartLabels}
            />
          </Col>
          <Col className="col-lg mb-4">
            <SmallStats
              variation="1"
              label="Batches"
              value={itemsCount.batch_count}
              chartData= {smallStats[2].datasets}
              chartLabels={smallStats[2].chartLabels}
            />
          </Col>
          <Col className="col-lg mb-4">
            <SmallStats
              variation="1"
              label="Quizzes"
              value={itemsCount.quiz_count}
              chartData= {smallStats[3].datasets}
              chartLabels={smallStats[3].chartLabels}
            />
          </Col>
          <Col className="col-lg mb-4">
            <SmallStats
              variation="1"
              label="Quick-Quizzes"
              value={itemsCount.quick_quiz_count}
              chartData= {smallStats[4].datasets}
              chartLabels={smallStats[4].chartLabels}
            />
          </Col>
      </Row>
      )}
      {/* Teacherwise Items Count*/}
        {user && user.roles===2 && (
        <Row>
          <Col className="col-lg-2 mb-4">
            <SmallStats
              variation="1"
              label="Quizzes"
              value={itemsCount.quiz_count}
              chartData= {smallStats[3].datasets}
              chartLabels={smallStats[3].chartLabels}
            />
          </Col>
          <Col className="col-lg-2 mb-4">
            <SmallStats
              variation="1"
              label="Quick-Quizzes"
              value={itemsCount.quick_quiz_count}
              chartData= {smallStats[4].datasets}
              chartLabels={smallStats[4].chartLabels}
            />
          </Col>
      </Row>
      )}

      <Row>
        <Col lg="8" md="12" sm="12" className="mb-4">
          <QuizGraph />
        </Col>

        <Col lg="4" md="6" sm="12" className="mb-4">
          <QuizPiechart data={itemsCount}/>
        </Col>

      </Row>
    </Container>
  );
};

InstituteOverview.defaultProps = {
  smallStats: [
    {
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [3, 2, 3, 2, 4, 5, 4]
        }
      ]
    }
  ]
};

export default withRouter(InstituteOverview);
