import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Row,
  Col
} from "shards-react";
import "antd/dist/antd.css";
import { Popover } from "antd";

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import PopUp from "../../components/common/PopUp";

import ClassForm from "./components/ClassForm";

import { getBatches } from "../../redux/actions/batchActions";
import {
  getClasses,
  deleteClass,
  createClass,
  editClass
} from "../../redux/actions/classActions";

const Class = () => {
  const dispatch = useDispatch();
  //here item is referring to class
  const [addItem, setAddItem] = useState(true);

  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState("");
  const [selectedBatches, setSelectedBatches] = useState([]);

  const batchList = useSelector(state => state.batch);
  const { batches } = batchList;
  const classList = useSelector(state => state.class);
  const { classes } = classList;

  const auth = useSelector(state => state.auth);
  const user = auth.user;

  //Get

  useEffect(() => {

    if (auth && user && user.institute) {
      dispatch(getClasses(user.institute.id));
      dispatch(getBatches(user.institute.id));
    }
  }, [dispatch, auth, user]);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = classes.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  //delete
  const deleteHandler = () => {
    dispatch(deleteClass(deleteItemId, user.institute.id));
    if (currentItems.length === 1) {
      paginate(Math.max(currentPage - 1, 1));
    }
    dispatch(getClasses(user.institute.id));
    showPopUp("Class Deleted Successfully!");
  };

  //edit and create
  function getBatchById(id) {
    const arr = batches.filter(x => (Number)(x.id) === (Number)(id));
    return arr[0];
  }

  const addBatches = id => {
    if (id !== "") {
      const batch = getBatchById(id);
      setSelectedBatches([...selectedBatches, batch]);
    }
  };

  const removeBatches = id => {
    setSelectedBatches(selectedBatches.filter(batch => batch.id !== id));
  };

  const submitHandler = e => {
    e.preventDefault();
    if (addItem === true) {
      const itemName = e.target.className.value;
      let batches = selectedBatches.map(x => x.id);
      const itemData = {
        class_name: itemName,
        batch_name: batches,
        status: true,
        institute: user.institute.id
      };
      dispatch(createClass(itemData, user.institute.id));
      if (currentItems.length === itemsPerPage) {
        paginate(currentPage + 1);
      }
      dispatch(getClasses(user.institute.id));
      showPopUp("Class added Successfully!");
    } else {
      const itemName = e.target.className.value;
      let batches = selectedBatches.map(x => x.id);
      const itemData = {
        id: itemId,
        class_name: itemName,
        batch_name: batches
      };

      dispatch(editClass(itemData, user.institute.id));
      dispatch(getClasses(user.institute.id));
      setAddItem(true);
      showPopUp("Class changed Successfully!");
    }
    setItemName("");
    setSelectedBatches([]);
  };

  const editHandler = classData => {
    setItemName(classData.class_name);
    setItemId(classData.id);
    setSelectedBatches(classData.batch_name);
    setAddItem(false);
  };

  const addItemTrue = () => {
    setAddItem(true);
    setItemName("");
    setSelectedBatches([]);
  };

  //popup
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = id => {
    setDeleteItemId(id);
    setDeletePopUpVisible(true);
  };

  const showPopUp = message => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  };

  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }}
        theme="white"
        type="default"
        size="small"
        className="mx-1"
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        type="primary"
        size="small"
        className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage} />
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Class"
          subtitle="Class"
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Class Form */}
      <Row>
        <Col lg="4" className="mb-4">
          <Card small>
            <CardHeader
              className="border-bottom"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h6
                className="m-0"
                style={{
                  display: "flex",
                  justifyContent: "flex-right",
                  flex: "1"
                }}
              >
                {addItem === true ? "Add Class" : "Edit Class"}
              </h6>
              {addItem === false && (
                <span
                  type="primary"
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: "1",
                    cursor: "pointer",
                    textAlign: "left",
                    verticalAlign: "center"
                  }}
                  onClick={() => addItemTrue()}
                >
                  <Button
                    type="primary"
                    size="small"
                    className="mx-1"
                    style={{ paddingBottom: "5px" }}
                  >
                    Cancel
                  </Button>
                </span>
              )}
            </CardHeader>
            <ClassForm
              batchArray={batches.filter(
                x => !selectedBatches.find(y => (Number)(y.id) === (Number)(x.id))
              )}
              submitHandler={submitHandler}
              addBatches={addBatches}
              removeBatches={removeBatches}
              selectedBatches={selectedBatches}
              classData={itemName}
              onClassNameChange={e => setItemName(e.target.value)}
            />
          </Card>
        </Col>

        {/*Class List */}
        <Col lg="8" className="mb-4" style={{ textAlign: "center" }}>
          <Card small className="mb-4 " style={{ textAlign: "left" }}>
            <CardHeader className="border-bottom">
              <h6 className="m-0">CLASS LIST</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Class Name
                    </th>
                    <th scope="col" className="border-0">
                      Batches
                    </th>
                    <th scope="col" className="border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(currentItems) &&
                    currentItems.map((data, index) => (
                      <tr key={data.id}>
                        <td>{index + 1 + itemsPerPage * (currentPage - 1)}.</td>
                        <td>{data.class_name}</td>

                        <td style={{ width: "40%" }}>
                          {data.batch_name.length !== 0 &&
                            data.batch_name.map((batch, index) => (
                              <p
                                key={batch.id}
                                style={{
                                  margin: "0",
                                  display: "inline-block"
                                }}
                              >
                                {batch.batch_name}
                                {index === data.batch_name.length - 1 ? "" : ","}
                                &nbsp;
                              </p>
                            ))}
                        </td>

                        <td>
                          <Button
                            type="primary"
                            size="small"
                            className="mx-1"
                            onClick={() => editHandler(data)}
                          >
                            Edit
                          </Button>
                          <Popover
                            key={data.id}
                            content={content}
                            title="Sure to delete?"
                            trigger="click"
                            visible={
                              deletePopUpVisible && (Number)(data.id) === (Number)(deleteItemId)
                            }
                            className="mx-1"
                          >
                            <Button
                              onClick={() => openDeletePopUp(data.id)}
                              theme="danger"
                              type="danger"
                              size="small"
                            >
                              Delete
                            </Button>
                          </Popover>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
          {classes !== undefined && classes.length > itemsPerPage && (
            <Pagination
              paginate={paginate}
              currentPage={currentPage}
              totalItems={classes.length}
              itemsPerPage={itemsPerPage}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Class);
