import { API } from "../../backend";
import axios from "axios";
import { QUICK_QUIZ_SUBMISSIONS_LOADED, QUICK_QUIZ_SUBMISSIONS_LOADING, SET_QUICK_QUIZ_SUBMISSIONS } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getQuickQuizSubmissions = (sessionId,instituteId,quizId) => async dispatch => {
  dispatch(quickQuizSubmissionsLoading());
  axios
    .get(`${API}/answer-quick-quiz?id=&session=${sessionId}&institute=${instituteId}&student=&quick_quiz__id=${quizId}`)
    .then(res => {
      const submissions = res.data.results;
      dispatch(setQuickQuizSubmissions(submissions));
      dispatch(quickQuizSubmissionsLoaded());
    })
    .catch(err => {
      dispatch(quickQuizSubmissionsLoaded());
    });
};


export const getQuickQuizSubmissionsByStudent = (sessionId,instituteId,quizId,userId) => async dispatch => {
  dispatch(quickQuizSubmissionsLoading());
  axios
    .get(`${API}/answer-quick-quiz?id=&session=${sessionId}&institute=${instituteId}&student=${userId}&quick_quiz__id=${quizId}`)
    .then(res => {
      const submissions = res.data.results;
      dispatch(setQuickQuizSubmissions(submissions));
      dispatch(quickQuizSubmissionsLoaded());
    })
    .catch(err => {
      dispatch(quickQuizSubmissionsLoaded());
    });
};

export const setQuickQuizSubmissions = submissions => {
  return {
    type: SET_QUICK_QUIZ_SUBMISSIONS,
    payload: submissions
  };
};

export const quickQuizSubmissionsLoading = () => {
  return {
    type: QUICK_QUIZ_SUBMISSIONS_LOADING
  };
};

export const quickQuizSubmissionsLoaded = () => {
  return {
    type: QUICK_QUIZ_SUBMISSIONS_LOADED
  };
};



export const updateMarks = (questionId,userId,marks) => async dispatch => {
  const res = await  axios.get(`${API}/answer-quiz?id=&session=&institute=&quiz_name=&question=${questionId}&user=${userId}`)
  const answerId = res.data.results[0].id;
  await  axios.put(`${API}/answer-quiz/${answerId}`,marks)
};