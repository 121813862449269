import React, { useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";
import { withRouter } from "react-router-dom";
import { API } from "../../../backend";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
toast.configure();

const ChangePassword = props => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [match, setMatch] = useState("");
  

  const changePassword = async () => {
      const data = {
        mobile:props.phone,
        otp:props.otp,
        password:password
      }
      if(password.length>=6 && password === confirmPassword){
        await axios.patch(`${API}/reset/`,data)
        toast("Your password has been changed successfully!!", { type: "success" });
        props.history.push('/')
      }
      else if(password.length <6 ){
        setMatch("lessLength")
      }
      else{
        setMatch("notMatch");
      }
  }

  const warning = {
    color: "red",
    margin: "0",
    fontSize: "14px",
    fontWeight: "400",
    marginLeft: "2px"
  };

  return (
    <Col md={4} lg={4} sm={12} xs={12}>
      <div className="signInForm">
        <div className="th-signin">
          <div className="container th-signin-container">
            <h1 style={{ color: "#fff" }}>
              <i class="material-icons">lock_outline</i>
            </h1>
            <h4 className="SignIn_header">Change Password</h4>
            <hr className="SignIn_line" />
            <ListGroup flush>
              <ListGroupItem className="p-3 th-signin-form-wraper">
                <Form>
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      className="form-group"
                    >
                      <label htmlFor="fePassword">New Password</label>
                      <FormInput
                        id="fePassword"
                        type={showPassword ? "text" : "password"}
                        placeholder="New Password"
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <p
                        className="signIn_showPass"
                        onClick={() =>
                          setShowPassword(showPassword === true ? false : true)
                        }
                      >
                        {showPassword?<i className="fas fa-eye-slash"></i>:<i className="fas fa-eye"></i>}
                      </p>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      className="form-group"
                    >
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <FormInput
                        id="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                      />
                      <p
                        className="signIn_showPass"
                        onClick={() =>
                          setShowConfirmPassword(showConfirmPassword === true ? false : true)
                        }
                      >
                        {showConfirmPassword?<i className="fas fa-eye-slash"></i>:<i className="fas fa-eye"></i>}
                      </p>
                      
                    </Col>
                    {match === "lessLength" && (
                        <p style={warning}>
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          Password must be at least 6 characters long
                        </p>
                      )}
                      {match === "notMatch" && (
                        <p style={warning}>
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          Password do not match!!
                        </p>
                      )}
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                      <Button className="btn btn-block" onClick={changePassword}>Change</Button>
                    </Col>

                    <Col className="back-to-login">
                      <a href="/">Back To Login</a>
                    </Col>
                  </Row>
                </Form>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default withRouter(ChangePassword);
