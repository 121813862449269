import React from 'react'

const Pagination = ({paginate, currentPage,totalItems,itemsPerPage}) => {
    const PageNumbers = [];
    const noOfPages = 3;
    const totalPages = Math.ceil(totalItems/itemsPerPage);
    if(currentPage<=noOfPages){
        for(let i=1;i<=Math.min(totalPages,noOfPages);i++){
            PageNumbers.push(i);
        }
    }
    else{
        for(let i=1;i<=noOfPages;i++){
            PageNumbers.push(i+currentPage-noOfPages);
        }
    }
    
    return (
        <nav>
            <ul className="pagination">
                {currentPage === 1 ? 
                    <></>:
                    <li className="page-item">
                        <p onClick={()=>paginate(currentPage-1)} className="page-item page-link" style={{color: "#fff",backgroundColor:"#007bff",borderRadius:"6px",marginRight:"5px",cursor:"pointer"}}>
                            previous
                        </p>
                    </li>   
                }
                {PageNumbers.map(number => (
                    number === currentPage ? 
                    <li key={number} className="page-item">
                        <p onClick={()=>paginate(number)} className="page-link" style={{color: "#fff",backgroundColor:"#007bff",cursor:"pointer",borderRadius:"6px",marginRight:totalPages!==currentPage?"5px":"0px"}}>
                            {number}
                        </p>
                    </li> :
                    <li key={number} className="page-item">
                        <p onClick={()=>paginate(number)} className="page-item page-link"  style={{marginRight:"5px",cursor:"pointer"}}>
                            {number}
                        </p>
                    </li>
                ))}
                { totalPages >currentPage &&
                    <li className="page-item">
                        <p onClick={()=>paginate(currentPage+1)} className="page-link" style={{color: "#fff",backgroundColor:"#007bff",borderRadius:"6px",cursor:"pointer"}}>
                            next
                        </p>
                    </li>
                    
                } 
            </ul>
        </nav>
    )
}

export default Pagination
