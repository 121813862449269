import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  CardHeader,
  Card, 
  Button,
  Container, 
  Row, 
  Col,
  ListGroup,
  ListGroupItem
} from "shards-react";

import 'antd/dist/antd.css';
import { Popover, Avatar } from 'antd';

import PageTitle from "../../components/common/PageTitle";
import PopUp from "../../components/common/PopUp";

import QuizForm from "../quiz/components/QuizForm";

import { getQuizzes, deleteQuiz, createQuiz, editQuiz, setCurrentQuizHandler } from "../../redux/actions/quizActions";

const Quiz = (props) => {

  const color = ["red","DeepPink","blue","green","Navy","Purple","Gold","OliveDrab","Orange","RosyBrown"];

  const randomColor = (data) => {
    // random function to get an index
    const idx = (data.id*210 + 45 + data.id%13)%10;
    // const idx = 9;
    return color[idx];
  }

  const dispatch = useDispatch();
  const [addItem,setAddItem] = useState(true);
  const [item,setItem] = useState(null);

  const quiz = useSelector(state => state.quiz);
  const auth = useSelector(state => state.auth);
  const session = useSelector(state => state.session);
  
  const items = quiz.quizzes;
  const user = auth.user;

  const [view, setView] = useState("list");

  // Get


  useEffect(() => {
    if(user.institute){
      dispatch(getQuizzes(session.curSession,user.institute.id));
    }
  },[dispatch]);

  //Date Format Function

  const dateFunction = (date) => {
    return (
      `${(new Date(date).getDate() < 10 ? '0' : '') + 
      new Date(date).getDate()}-${(new Date(date).getMonth() + 1 < 10 ? '0' : '') + 
      (new Date(date).getMonth() + 1)}-${new Date(date).getFullYear()}`
    )
  }
  
  // Pop up
  const [popMessage, setPopMessage] = useState("");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);

  const openDeletePopUp = (id) => {
    setDeleteItemId(id);
    setDeletePopUpVisible(true);
  }  

  const showPopUp = (message) => {
    setPopMessage(message);
    const element = document.getElementById("popUp");
    element.classList.remove("animate1");
    void element.offsetWidth;
    element.classList.add("animate1");
  }

  // Delete

  const deleteHandler = async() => {
    dispatch(deleteQuiz(deleteItemId, session.curSession, user.institute.id));
    showPopUp("Item deleted Successfully!");
  }
  
  // Edit
  const editHandler = (data) => {
    setItem(data);
    setAddItem(false);
    setView("form");
  }

  // Create and Edit

  const submitHandler = (e) => {
    e.preventDefault();
    var data = new FormData();
    if(addItem===true){
      data.append("question_type", e.target.question_type.value);
      data.append("quiz_name", e.target.quizName.value);
      data.append("quiz_type", e.target.quizType.value);
      data.append("quiz_subject_type", e.target.quizSubjectType.value);
      data.append("duration_minutes", e.target.duration.value);
      data.append("start_time", e.target.startTime ? e.target.startTime.value : "00:00:00");
      data.append("end_time", e.target.endTime ? e.target.endTime.value : "00:00:00");
      data.append("date", e.target.date.value);
      data.append("is_paid", e.target.isPaid.value);
      data.append("is_negative_marking", e.target.isNegativeMarking.value);
      data.append("is_partial_marking", e.target.isPartialMarking.value);
      data.append("class_name", e.target.className.value);
      data.append("batch_name", e.target.batchName.value);
      if(e.target.quizSubjectType.value==="single"){
        let subject = e.target.subjectNameSingle.value;
        data.append("single_subject", subject);
      }
      else{
        let subjects = JSON.parse(e.target.subjectNameMultiple.value);
        subjects.forEach((item) => data.append("multiple_subject", item))
      }
      data.append("status", true);
      data.append("session", session.curSession);
      data.append("institute", user.institute.id);
      data.append("quiz_image", e.target.file.files[0] ? e.target.file.files[0] : null);
      dispatch(createQuiz(data, session.curSession, user.institute.id));
      e.target.reset();
      showPopUp("Quiz added Successfully!");
      setView("list");
    }
    else{
      data.append("question_type", e.target.question_type.value);
      data.append("quiz_name", e.target.quizName.value);
      data.append("quiz_type", e.target.quizType.value);
      data.append("duration_minutes", e.target.duration.value);
      data.append("start_time", e.target.startTime ? e.target.startTime.value : "00:00:00");
      data.append("end_time", e.target.endTime ? e.target.endTime.value : "00:00:00");
      data.append("date", e.target.date.value);
      data.append("is_paid", e.target.isPaid.value);
      data.append("is_negative_marking", e.target.isNegativeMarking.value);
      data.append("is_partial_marking", e.target.isPartialMarking.value);
      if(e.target.file.files[0]){
        data.append("quiz_image", e.target.file.files[0]);
      }
      const id = e.target.quizID.value;
      dispatch(editQuiz(data, id, session.curSession, user.institute.id));
      setView("list");
      showPopUp("Quiz edited Successfully!");
    }
  }

  const setQuizHandler = (data) => {
    dispatch(setCurrentQuizHandler(data));
    props.history.push(`/questions/${data.id}`);
  }

  const content = (
    <>
      <Button
        onClick={() => {
          setDeleteItemId(null);
          setDeletePopUpVisible(false);
        }} 
        theme="white" size="sm" className="mx-1"
      >
        Cancel
      </Button>
      <Button 
        onClick={()=>{
          deleteHandler();
          setDeletePopUpVisible(false);
        }}
        size="sm" className="mx-1"
      >
        OK
      </Button>
    </>
  );

  return (
    <Container fluid className="main-content-container px-4">
      <PopUp message={popMessage}/>
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Quiz"
          subtitle="Quiz"
          className="text-sm-left mb-3"
        />
        {view==="form" ? 
          <Button type="primary" size="sm" onClick={() => {setView("list");setItem(null);setAddItem(true)}}  className="ml-auto mb-3">
            Back
          </Button>
          :
          <Button type="primary" size="sm" onClick={() => {setView("form");setItem(null);setAddItem(true)}}  className="ml-auto mb-3">
            New Quiz
          </Button>
        }
      </Row>
  
      {/* Quiz Form */}
      <Row>
        {view==="form" ? 
          <Col lg="12" className="mb-4">
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">{item===null ? "Add" : "Edit"} Quiz</h6>
              </CardHeader>
              <QuizForm submitHandler = {submitHandler} data={item}/>
            </Card>
          </Col>
           : 
           <Col lg="12" className="mb-4">
            <Row className="p-5">
              {items.map((data,index) => 
                <Col key={index} lg="3" md="6" className="mb-4">
                  <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-center">
                      <div className="mb-3 mx-auto">
                        <Avatar 
                          style={{backgroundColor:randomColor(data)}}
                          onClick={() => setQuizHandler(data)} shape="circle" size={80}
                        >
                          {data.quiz_name[0].toUpperCase()}
                        </Avatar>
                      </div>
                      <h4 className="mb-1">{data.quiz_name}</h4>
                      <strong className="text-muted d-block mb-2">
                          Class Name : {data.class_name.class_name}
                        </strong>
                      <Button
                        onClick={() => editHandler(data)}
                        size="sm" 
                        pill 
                        outline 
                        shape="circle" 
                        className="mx-1 mb-2"
                      >
                        Edit
                      </Button>
                      <Popover 
                        key={data.id}
                        content={content} 
                        title="Sure to delete?" 
                        trigger="click" 
                        visible={deletePopUpVisible && data.id===deleteItemId}
                        className="mx-1"
                      >
                        <Button 
                          onClick={() => openDeletePopUp(data.id)}
                          pill
                          outline
                          size="sm" 
                          theme="danger" 
                          className="mx-1 mb-2" 
                        >
                          Delete
                        </Button>
                      </Popover>
                    </CardHeader>
                    <ListGroup flush>
                      <ListGroupItem className="px-4">
                        <strong className="text-muted d-block mb-2">
                          Date : {dateFunction(data.date)}
                        </strong>
                        <strong className="text-muted d-block mb-2">
                          Duration : {data.duration_minutes} minutes
                        </strong>
                      </ListGroupItem>
                    </ListGroup>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        }
      </Row>
    </Container>
  );
};

export default withRouter(Quiz);
