import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Row,
  Col,
  CardTitle,
  FormInput
} from "shards-react";
import "antd/dist/antd.css";

import PageTitle from "../../../components/common/PageTitle";
import Pagination from "../../../components/common/Pagination";

import {
  getQuickQuizSubmissions,
  getQuickQuizSubmissionsByStudent
} from "../../../redux/actions/quickQuizSubmissionsActions";

import {
  getQuickQuizSubmissionFilesByStudent,
  updateFileMarks
} from "../../../redux/actions/quickQuizSubmissionFileActions";

import folderImage from "../../../images/signin/folder.png";
import axios from "axios";
import { API } from "../../../backend";


const StudentQuickQuizSubmission = props => {
  const dispatch = useDispatch();
  //here item is referring to Student
  const quizId = props.match.params.id;

  const [view, setView] = useState("StudentList");

  const quickQuizSubmissionsList = useSelector(
    state => state.quickQuizSubmission
  );
  const { loading, submissions } = quickQuizSubmissionsList;

  const quickQuizSubmissionFilesList = useSelector(
    state => state.quickQuizSubmissionFile
  );
  const {
    loading: loadingFiles,
    submissionFiles
  } = quickQuizSubmissionFilesList;

  const auth = useSelector(state => state.auth);
  const session = useSelector(state => state.session);
  const user = auth.user;

  const [quiz, setQuiz] = useState(null);

  const [screenSize,setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth)
  }
  
  useEffect(()=>{
    window.addEventListener("resize", handleResize)
  },)
  

  // get
  useEffect(() => {
    if (user && user.institute && session && session.curSession && quizId) {
      dispatch(
        getQuickQuizSubmissions(session.curSession, user.institute.id, quizId)
      );
      axios.get(`${API}/quick-quiz/${quizId}`).then(res => {
        setQuiz(res.data);
      });
    }
  }, [dispatch, quizId, user, session.curSession]);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = submissions.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };


  const showStudentAnswers = async data => {
    dispatch(
      getQuickQuizSubmissionsByStudent(
        session.curSession,
        user.institute.id,
        quizId,
        data.student.id
      )
    );
    dispatch(
      getQuickQuizSubmissionFilesByStudent(
        session.curSession,
        user.institute.id,
        quizId,
        data.student.id
      )
    );
    setView("StudentAnswer");
  };


  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={quiz&&quiz.quiz_name}
          subtitle="Students Submission"
          className="text-sm-left mb-3"
        />
        {view === "StudentList" ? (
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              props.history.push(`/quick-quiz-submission`); //send to quiz list
            }}
            className="ml-auto mb-3"
          >
            Back
          </Button>
        ) : (
          <Button
            type="primary"
            size="sm"
            onClick={() => {
              setView("StudentList"); //send to student list
              dispatch(
                getQuickQuizSubmissions(
                  session.curSession,
                  user.institute.id,
                  quizId
                )
              );
            }}
            className="ml-auto mb-3"
          >
            Back
          </Button>
        )}
      </Row>

      <Row>
        {/* Sutudents List */}
        {view === "StudentList" ? (
          <Col lg="8" className="mb-4" style={{ textAlign: "center" }}>
            <Card small className="mb-4 " style={{ textAlign: "left" }}>
              <CardHeader className="border-bottom">
                <h6 className="m-0">STUDENTS SUBMITTED</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Students
                      </th>
                      <th scope="col" className="border-0">
                        Marks
                      </th>
                      <th scope="col" className="border-0">
                        Submissions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false &&
                      Array.isArray(currentItems) &&
                      currentItems.map((data, index) => (
                        <tr key={index}>
                          <td>
                            {index + 1 + itemsPerPage * (currentPage - 1)}.
                          </td>
                          <td>{data.student.full_name}</td>
                          <td>
                            {data.marks_obtained}/{quiz&&quiz.total_marks}
                          </td>
                          <td>
                            <Button
                              type="primary"
                              size="small"
                              className="mx-1"
                              onClick={() => showStudentAnswers(data)}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
            {submissions !== undefined && submissions.length > itemsPerPage && (
              <Pagination
                paginate={paginate}
                currentPage={currentPage}
                totalItems={submissions.length}
                itemsPerPage={itemsPerPage}
              />
            )}
          </Col>
        ) : (
          <>
            {loadingFiles === false &&
              loading === false &&
              submissions.length !== 0 &&
              submissions.map((data, index) => {
                return (
                  <Col md="12" key={index} className="mb-3">
                    <Card>
                      <CardTitle className="border-bottom d-flex flex-row px-2">
                        <span className="mr-auto ml-2 mt-3 mb-2">
                          {data.student.full_name} Submissions
                        </span>
                        {screenSize >= 450 && 
                          <span className="mr-2 mt-3 mb-2 d-flex flex-row">
                          Each question: {(quiz.total_marks/quiz.no_of_question).toFixed(2)} marks
                        </span> 
                        }

                      </CardTitle>
                      <CardBody
                        className="pt-0 question-card"
                        style={{ textAlign: "right" }}
                      >
                        {data && data.quick_quiz.no_of_question <= 10 ? (
                          Object.entries(JSON.parse(data.omr_answer)).map(
                            (x, index) => {
                              return (
                                <span
                                  key={index}
                                  className="mr-auto ml-2 mt-3 mb-2 d-flex flex-row"
                                >
                                  Q{x[0]}
                                  {".  "}
                                  {x[1] ||
                                    submissionFiles.filter(file => file.question_no === Number(x[0])).map((file, idx) => {
                                        return (
                                          <span
                                            className="d-flex flex-row"
                                            key={idx}
                                          >
                                            <a
                                              href={file.answer_file}
                                              style={{
                                                textDecoration: "none",
                                                color: "#000"
                                              }}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img
                                                src={folderImage}
                                                alt="Answer File"
                                                width="30"
                                                height="30"
                                              />{" "}
                                              {((screenSize>=400&&screenSize<=750)||screenSize>=880)&&"Solution File"}
                                            </a>
                                            <FormInput
                                              type="number"
                                              id="marks"
                                              placeholder="marks"
                                              className="mx-2"
                                              size="sm"
                                              style={{
                                                width: "20%",
                                                height:"80%",
                                                borderColor: "grey"
                                              }}
                                              defaultValue={file.marks_obtained}
                                              onChange={e =>
                                                dispatch(
                                                  updateFileMarks(file.id, {
                                                    marks_obtained:
                                                      Math.min(e.target.value || 0,(quiz.total_marks/quiz.no_of_question).toFixed(2))
                                                  })
                                                )
                                              }
                                            />
                                            <h6 style={{color:"#000"}}>{"/ "}{(quiz.total_marks/quiz.no_of_question).toFixed(2)}</h6>
                                          </span>
                                        );
                                    })}
                                  &nbsp;
                                  {x[1] !== null &&
                                    (x[1] ===
                                    Object.entries(
                                      JSON.parse(data.quick_quiz.omr_answer)
                                    )[index][1] ? (
                                      <span style={{ color: "green" }}>
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span>
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        ></i>
                                      </span>
                                    ))}
                                </span>
                              );
                            }
                          )
                        ) : (
                          <Row className="d-flex flex-row">
                            <Col lg="6" xl="6" md="6">
                              {Object.entries(JSON.parse(data.omr_answer)).filter((x,index) => (index + 1 <=Math.floor((data.quick_quiz.no_of_question + 1) / 2 ))).map((x, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className="mr-auto ml-2 mt-3 d-flex flex-row"
                                      >
                                        Q{x[0]}
                                        {".  "}
                                        {x[1] ||
                                          submissionFiles.filter(file => file.question_no === Number(x[0])).map((file, idx) => {
                                            
                                              return (
                                                <span
                                                  className="d-flex flex-row"
                                                  key={idx}
                                                  style={{
                                                    flexFlow: "column wrap"
                                                  }}
                                                >
                                                  <a
                                                    href={file.answer_file}
                                                    style={{
                                                      textDecoration: "none",
                                                      color: "#000"
                                                    }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={folderImage}
                                                      alt="Answer File"
                                                      width="30"
                                                      height="30"
                                                    />{" "}
                                                    {((screenSize>=400&&screenSize<=750)||screenSize>=880)&&"Solution File"}
                                                  </a>
                                                  <FormInput
                                                    type="number"
                                                    id="marks"
                                                    placeholder="marks"
                                                    className="mx-2"
                                                    size="sm"
                                                    style={{
                                                      width: "20%",
                                                      height:"80%",
                                                      borderColor: "grey"
                                                    }}
                                                    defaultValue={
                                                      file.marks_obtained
                                                    }
                                                    onChange={e =>
                                                      dispatch(
                                                        updateFileMarks(
                                                          file.id,
                                                          {
                                                            marks_obtained:
                                                               Math.min(e.target.value || 0,(quiz.total_marks/quiz.no_of_question).toFixed(2))
                                                          }
                                                        )
                                                      )
                                                    }
                                                  />
                                                  <h6 style={{color:"#000"}}>{"/ "}{(quiz.total_marks/quiz.no_of_question).toFixed(2)}</h6>
                                                </span>
                                              );
                                            
                                          })}
                                        &nbsp;
                                        {x[1] !== null &&
                                          (x[1] ===
                                          Object.entries(
                                            JSON.parse(
                                              data.quick_quiz.omr_answer
                                            )
                                          )[index][1] ? (
                                            <span style={{ color: "green" }}>
                                              <i
                                                className="fa fa-check"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          ) : (
                                            <span>
                                              <i
                                                className="fa fa-times"
                                                aria-hidden="true"
                                                style={{ color: "red" }}
                                              ></i>
                                            </span>
                                          ))}
                                      </span>
                                    );
                                  
                                }
                              )}
                            </Col>
                            <Col lg="6" xl="6" md="6">
                              {Object.entries(JSON.parse(data.omr_answer)).filter((x,index) => (index + 1 >Math.floor((data.quick_quiz.no_of_question + 1) / 2 ))).map((x, index) => {
                                  
                                    return (
                                      <span
                                        key={index}
                                        className="mr-auto ml-2 mt-3 d-flex flex-row"
                                      >
                                        Q{x[0]}
                                        {".  "}
                                        {x[1] ||
                                          submissionFiles.filter(file => file.question_no === Number(x[0])).map((file, idx) => {
                                            
                                              return (
                                                <span
                                                  className="d-flex flex-row"
                                                  key={idx}
                                                  style={{
                                                    flexFlow: "column wrap"
                                                  }}
                                                >
                                                  <a
                                                    href={file.answer_file}
                                                    style={{
                                                      textDecoration: "none",
                                                      color: "#000"
                                                    }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={folderImage}
                                                      alt="Answer File"
                                                      width="30"
                                                      height="30"
                                                    />{" "}
                                                    {((screenSize>=400&&screenSize<=750)||screenSize>=880)&&"Solution File"}
                                                  </a>
                                                  <FormInput
                                                    type="number"
                                                    id="marks"
                                                    placeholder="marks"
                                                    className="mx-2"
                                                    size="sm"
                                                    style={{
                                                      width:"20%",
                                                      height:"80%",
                                                      borderColor: "grey"
                                                    }}
                                                    defaultValue={
                                                      file.marks_obtained
                                                    }
                                                    onChange={e =>
                                                      dispatch(
                                                        updateFileMarks(
                                                          file.id,
                                                          {
                                                            marks_obtained:
                                                               Math.min(e.target.value || 0,(quiz.total_marks/quiz.no_of_question).toFixed(2))
                                                          }
                                                        )
                                                      )
                                                    }
                                                  />
                                                  <h6 style={{color:"#000"}}>{"/ "}{(quiz.total_marks/quiz.no_of_question).toFixed(2)}</h6>
                                                </span>
                                              );
                                            
                                          })}
                                        &nbsp;
                                        {x[1] !== null &&
                                          (x[1] ===
                                          Object.entries(
                                            JSON.parse(
                                              data.quick_quiz.omr_answer
                                            )
                                          )[index][1] ? (
                                            <span style={{ color: "green" }}>
                                              <i
                                                className="fa fa-check"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          ) : (
                                            <span>
                                              <i
                                                className="fa fa-times"
                                                aria-hidden="true"
                                                style={{ color: "red" }}
                                              ></i>
                                            </span>
                                          ))}
                                      </span>
                                    );
              
                                }
                              )}
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </>
        )}
      </Row>
    </Container>
  );
};

export default withRouter(StudentQuickQuizSubmission);
