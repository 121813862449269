import React, { Component } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Button,
  Form
} from "shards-react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginUser } from "../../redux/actions/authActions";

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      password: "",
      passwordShown: false //for password showing
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to home
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/"); // push user to Home page when they login
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLogin = event => {
    event.preventDefault();
    const userData = {
      mobile: this.state.mobile,
      password: this.state.password
    };
    this.props.loginUser(userData, this.props.history);
  };

  render() {
    return (
      <div className="SignInPage">
        <div className="signInRow">
          <Col md={8} lg={8}>
            <div className="SignInImage"></div>
          </Col>
          <Col md={4} lg={4} sm={12} xs={12}>
            <div className="signInForm">
              <div className="th-signin">
                <div className="container th-signin-container">
                  <h2 className="SignIn_header">We are Zixwer</h2>
                  <p className="SignIn_subheader">
                    Welcome back. Please login to your account
                  </p>
                  <hr className="SignIn_line" />
                  <ListGroup flush>
                    <ListGroupItem className="p-3 th-signin-form-wraper">
                      <Form onSubmit={this.handleLogin}>
                        <Row>
                          <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            className="form-group"
                          >
                            <label htmlFor="femobileAddress">
                              Mobile Number
                            </label>
                            <FormInput
                              id="femobileAddress"
                              type="number"
                              placeholder="Mobile"
                              name="mobile"
                              value={this.state.mobile}
                              onChange={this.handleChange}
                              required
                            />
                          </Col>
                          <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            className="form-group"
                          >
                            <label htmlFor="fePassword">Password</label>
                            <FormInput
                              id="fePassword"
                              type={
                                this.state.passwordShown ? "text" : "password"
                              }
                              placeholder="Password"
                              name="password"
                              value={this.state.password}
                              onChange={this.handleChange}
                              required
                            />
                            <p
                              className="signIn_showPass"
                              onClick={() =>
                                this.setState({
                                  passwordShown: this.state.passwordShown
                                    ? false
                                    : true
                                })
                              }
                            >
                              {this.state.passwordShown?<i className="fas fa-eye-slash"></i>:<i className="fas fa-eye"></i>}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label className="SignIn_terms">
                              <input type="checkbox" required />I
                              agree to terms & conditions
                            </label>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <Button
                              className="btn btn-block"
                              type="submit"
                            >
                              Login to Account
                            </Button>
                          </Col>
                          <Col className="forgotPassword">
                            <a href="/#/forgot-password">Forgot password?</a>
                          </Col>
                        </Row>
                      </Form>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { loginUser })(Signin));
